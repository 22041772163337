import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Nav } from './elements/Nav';
import ReactGA from "react-ga";
import { Helmet } from 'react-helmet';

import ModuleItem from "./elements/ModuleItem";
import SectionHeader from "./elements/SectionHeader";

import server from "../api/server";
import NewHomeNav from './elements/NewHomeNav';



export class AffiliateTOS extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            link: "",
            contact_info: "",
            partnership_message: "",
            disabled: false,
            submitted: false,
            error: false,
            size: 0
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }





    render() {
        return (
            <html>
                <Helmet>
                    <link rel="icon" type="image/png" sizes="16x16" href="/homestyles/img/favicon.ico" />
                    <title>Affiliate Terms and Conditions  | BotGhost</title>
                    {/* <meta name="description" content={`Are you looking for a ${this.state.tag} Discord Bot for your Discord community or server? We have compiled the best ${this.state.tag} Discord Bots that you can add to your Discord Server today.`}/> */}
                    <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,600,900|Open+Sans" rel="stylesheet" />
                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossorigin="anonymous" />
                    <link rel="stylesheet" type="text/css" href="/css/bootstrap.min.css" />
                    <link href="/css/bootstrap-colorpicker.min.css" rel="stylesheet" />
                    <link href="/dashboard/node_modules/toast-master/css/jquery.toast.css" rel="stylesheet" />
                    {/* <link rel="stylesheet" type="text/css" href="/css/dashcss.css"/> */}
                    <link rel="stylesheet" type="text/css" href="/css/newdash.css" />
                    <link href="https://fonts.googleapis.com/css2?family=Rajdhani:wght@500;700&display=swap" rel="stylesheet" />



                </Helmet>
                <body>
                    <div>
                        <NewHomeNav />

                        <main className="partner-program-container min-h-screen py-12 px-4 sm:px-6 lg:px-8 bg-[#1E2124]">
                            <div className="max-w-4xl mx-auto">
                                <div className="text-center mb-8">
                                    <img
                                        src={process.env.PUBLIC_URL + "/img/logo-red.png"}
                                        className="h-16 mx-auto mb-4"
                                        alt="BotGhost Logo"
                                    />
                                    <h1 className='text-4xl font-bold text-white mb-6 font-["Rajdhani"]'>Affiliate Terms and Conditions</h1>
                                </div>

                                <div className="bg-[#2C2F33] shadow-xl rounded-lg p-8">
                                    <p className="text-[#B9BBBE] mb-6">As an authorized affiliate (Affiliate) of BotGhost, you agree to abide by the terms and conditions contained in this Agreement (Agreement). Please read the entire Agreement carefully before registering and promoting BotGhost as an Affiliate.</p>

                                    <p className="text-[#B9BBBE] mb-6">Your participation in the Program is solely to legally advertise our website to receive a commission on memberships and products purchased by individuals referred to BotGhost by your own website or personal referrals.</p>

                                    <p className="text-[#B9BBBE] mb-8">By signing up for the BotGhost Affiliate Program (Program), you indicate your acceptance of this Agreement and its terms and conditions.</p>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">1. Approval or Rejection of the Application</h2>
                                    <p className="text-[#B9BBBE] mb-8">We reserve the right to approve or reject ANY Affiliate Program Application at our sole and absolute discretion. You will have no legal recourse against us for the rejection of your Affiliate Program Application.</p>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">2. Commissions</h2>
                                    <ul className="list-disc list-inside text-[#B9BBBE] space-y-2 mb-8 ml-4">
                                        <li>Commissions will be paid once a month. For an Affiliate to receive a commission, the referred account must remain active for a minimum of 7 days.</li>
                                        <li>Commissions require a minimum balance of $50 to be eligible for payout.</li>
                                        <li>Affiliates cannot refer themselves, and no commission will be earned on your own accounts. There is also a limit of one commission per referral. If someone clicks the link on your site and orders multiple accounts, you will only receive a commission on the first order.</li>
                                        <li>Payments will only be sent for transactions that have been successfully completed. Transactions that result in chargebacks or refunds will not be paid out.</li>
                                    </ul>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">3. Termination</h2>
                                    <p className="text-[#B9BBBE] mb-4">We reserve the right to suspend or terminate your affiliate application and status in the Program for any of the following reasons:</p>
                                    <ul className="list-disc list-inside text-[#B9BBBE] space-y-2 mb-8 ml-4">
                                        <li><span className="font-semibold text-[#FF4654]">Inappropriate advertisements</span> (false claims, misleading hyperlinks, etc.).</li>
                                        <li><span className="font-semibold text-[#FF4654]">Spamming</span> (mass email, mass newsgroup posting, etc.).</li>
                                        <li><span className="font-semibold text-[#FF4654]">Advertising on sites</span> containing or promoting illegal activities.</li>
                                        <li><span className="font-semibold text-[#FF4654]">Failure to disclose the affiliate relationship</span> for any promotion that qualifies as an endorsement under existing Federal Trade Commission guidelines and regulations, or any applicable state laws.</li>
                                        <li><span className="font-semibold text-[#FF4654]">Violation of intellectual property rights.</span> BotGhost reserves the right to require license agreements from those who employ trademarks of BotGhost to protect our intellectual property rights.</li>
                                        <li><span className="font-semibold text-[#FF4654]">Offering rebates, coupons, or other forms of promised kick-backs</span> from your affiliate commission as an incentive. Adding bonuses or bundling other products with BotGhost is acceptable.</li>
                                        <li><span className="font-semibold text-[#FF4654]">Self-referrals, fraudulent transactions, or suspected Affiliate fraud.</span></li>
                                        <li><span className="font-semibold text-[#FF4654]">Advertising your affiliate link on any content that is not yours. This includes the BotGhost Discord Server.</span></li>
                                        <li><span className="font-semibold text-[#FF4654]">Any other actions that we deem to be in violation of the terms and conditions of this Agreement.</span></li>
                                    </ul>

                                    <p className="text-[#B9BBBE] mb-6">Violations will result in immediate removal from the Program. Any outstanding commissions will be forfeited.</p>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">4. Affiliate Links</h2>
                                    <p className="text-[#B9BBBE] mb-6">You may use graphic and text links both on your website and within your email messages. You may also advertise the BotGhost site in online and offline classified ads, magazines, and newspapers.</p>

                                    <p className="text-[#B9BBBE] mb-6">You may use the graphics and text provided by us, or you may create your own as long as they are deemed appropriate according to the conditions and not in violation of Section 3.</p>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">5. Coupon and Deal Sites</h2>
                                    <p className="text-[#B9BBBE] mb-6">BotGhost occasionally offers coupons to select affiliates and our newsletter subscribers. If you’re not pre-approved/assigned a branded coupon, then you’re not allowed to promote the coupon. Below are the terms that apply for any affiliate who is considering promoting our products in relation to a deal or coupon:</p>
                                    <ul className="list-disc list-inside text-[#B9BBBE] space-y-2 mb-8 ml-4">
                                        <li>Affiliates may not use misleading text on affiliate links, buttons, or images to imply unauthorized deals.</li>
                                        <li>Affiliates may not bid on terms like "BotGhost Coupons," "BotGhost Discounts," or other phrases implying coupons are available.</li>
                                        <li>Affiliates may not generate pop-ups, pop-unders, iframes, frames, or any other seen or unseen actions that set affiliate cookies unless the user has explicitly expressed interest in activating a specific savings by clicking on a clearly marked link, button, or image.</li>
                                        <li>Users must be able to see coupon/deal/savings information and details before an affiliate cookie is set.</li>
                                        <li>Affiliate sites may not include text like "Click for (or to see) Deal/Coupon" or any variation when no coupons or deals are available.</li>
                                    </ul>
                                    <p className="text-[#B9BBBE] mb-6">Violations will result in immediate removal from the Program.</p>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">6. Pay Per Click (PPC) Policy</h2>
                                    <p className="text-[#B9BBBE] mb-6">PPC bidding is NOT allowed without prior written permission.</p>

                                    <hr className="my-8 border-[#40444B]" />
                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">7. Liability</h2>
                                    <p className="text-[#B9BBBE] mb-6">BotGhost will not be liable for indirect or accidental damages (loss of revenue, commissions) due to affiliate tracking failures, loss of database files, or any results of intents of harm to the Program and/or to our website(s).</p>

                                    <p className="text-[#B9BBBE] mb-8">We do not make any expressed or implied warranties with respect to the Program and/or the memberships or products sold by BotGhost. We make no claim that the operation of the Program and/or our website(s) will be error-free and we will not be liable for any interruptions or errors.</p>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">8. Term of the Agreement</h2>
                                    <p className="text-[#B9BBBE] mb-6">The term of this Agreement begins upon your acceptance in the Program and will end when your Affiliate account is terminated.</p>

                                    <p className="text-[#B9BBBE] mb-8">The terms and conditions of this agreement may be modified by us at any time. If any modification to the terms and conditions of this Agreement are unacceptable to you, your only choice is to terminate your Affiliate account. Your continuing participation in the Program will constitute your acceptance of any change.</p>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">9. Indemnification</h2>
                                    <p className="text-[#B9BBBE] mb-8">Affiliate shall indemnify and hold harmless BotGhost and its affiliate and subsidiary companies, officers, directors, employees, licensees, successors, and assigns, including those licensed or authorized by BotGhost to transmit and distribute materials, from any and all liabilities, damages, fines, judgments, claims, costs, losses, and expenses (including reasonable legal fees and costs) arising out of or related to any and all claims sustained in connection with this Agreement due to the negligence, misrepresentation, failure to disclose, or intentional misconduct of Affiliate.</p>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">10. Governing Law, Jurisdiction, and Attorney Fees</h2>
                                    <p className="text-[#B9BBBE] mb-6">This Agreement shall be governed by and construed in accordance with the laws of the United States and the State of Florida. Any dispute arising under or related in any way to this Agreement shall be adjudicated exclusively in the state courts located in Palm Beach County, Florida.</p>

                                    <p className="text-[#B9BBBE] mb-8">In the event of litigation to enforce any provision of this Agreement, the prevailing party will be entitled to recover from the other party its costs and fees, including reasonable legal fees.</p>

                                    <hr className="my-8 border-[#40444B]" />

                                    <h2 className="text-2xl font-semibold text-white mb-4 font-['Rajdhani']">11. Electronic Signatures Effective</h2>
                                    <p className="text-[#B9BBBE] mb-8">The Agreement is an electronic contract that sets out the legally binding terms of your participation in the BotGhost affiliate program. You indicate your acceptance of this Agreement and all of the terms and conditions contained or referenced in this Agreement by completing the application process. This action creates an electronic signature that has the same legal force and effect as a handwritten signature.</p>

                                    <p className="text-[#B9BBBE] italic">By signing up for the BotGhost Affiliate Program, you acknowledge that you have read this Agreement and agree to all its terms and conditions. Failure to comply with these terms may result in suspension or termination of your affiliate account, and forfeiture of any outstanding commissions.</p>
                                </div>
                            </div>
                        </main>




                    </div>
                    <script src="https://js.stripe.com/v3/"></script>
                    <script type="text/javascript" src="https://unpkg.com/popper.js@1.15.0/dist/umd/popper.min.js"></script>
                    <script src="https://code.jquery.com/jquery-3.5.1.min.js" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
                    <script src="/js/bootstrap.min.js"></script>
                    <script src="/js/bootstrap-colorpicker.min.js"></script>
                    <script type="text/javascript" src="/js/angular.min.js"></script>
                    <script src="/dashboard/node_modules/toast-master/js/jquery.toast.js"></script>
                    <script type="text/javascript" src="/js/dash.js"></script>
                    <script data-ad-client="ca-pub-6273221994052603" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

                    <script async defer src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
                    <noscript><img src="https://queue.simpleanalyticscdn.com/noscript.gif" alt="" /></noscript>
                </body>
            </html>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default AffiliateTOS;
