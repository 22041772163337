import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import NewHomeNav from '../elements/NewHomeNav';
import SeoHeader from '../seo_elements/SeoHeader';
import FeatureSection from '../seo_elements/FeatureSection';
import SecondaryFeatures from '../seo_elements/SecondaryFeatures';
import Testimonial from '../seo_elements/Testimonial';
import CTABanner from '../seo_elements/CTABanner';
import FAQ from '../seo_elements/FAQ';
import TestimonialCollage from '../seo_elements/TestimonialCollage';
import StepByStep from '../seo_elements/StepByStep';
import YouTubeSection from '../seo_elements/YouTubeSection';
import MainNav from '../elements/MainNav';
import ModulesSection from '../seo_elements/ModulesSection';
import RedCTA from '../seo_elements/RedCTA';
import HomeCommandBuilder from '../elements/HomeCommandBuilder';
import TutorialsSection from '../seo_elements/TutorialsSection';
import SEOBuilderDemo from '../seo_elements/SEOBuilderDemo';
import DetailedTutorials from '../seo_elements/DetailedTutorials';
import StatsSection from '../seo_elements/StatsSection';
import HeroFeature from '../seo_elements/HeroFeature';

export class DiscordBotMaker extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {

        const mainFeatures = [
            {
                title: "Drag and Drop Bot Maker",
                description: "Create your Discord bot with our intuitive drag-and-drop interface. Design custom commands, events, and automations without writing a single line of code. Perfect for beginners and experts alike.",
                bulletPoints: [
                    "Visual command builder",
                    "No coding knowledge needed"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
            },
            {
                title: "Advanced Command Builder",
                description: "Build complex commands with our powerful visual builder. Create interactive menus, buttons, modals, and more using our drag-and-drop interface. Perfect for creating engaging bot interactions.",
                bulletPoints: [
                    "Interactive components",
                    "If/Else Statements",
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/CommandBuilderBG.png"
            },
            {
                title: "Pre-built Modules",
                description: "Choose from our extensive library of pre-built modules to add instant functionality to your bot. From welcome messages to moderation tools, we've got everything you need to enhance your server.",
                bulletPoints: [
                    "One-click module activation",
                    "Fully customizable"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/custommodules.png"
            },
            {
                title: "Variable System",
                description: "Create dynamic responses with our powerful variable system. Store and retrieve data, create counters, track user stats, and more - all without touching any code.",
                bulletPoints: [
                    "Dynamic data storage",
                    "Custom variables"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/VariablesBG.png"
            },
            {
                title: "Advanced Message Builder",
                description: "Create beautiful Discord embeds with our visual embed designer. Customize colors, add fields, images, and more. Preview your embeds in real-time as you build them.",
                bulletPoints: [
                    "Visual embed builder",
                    "Real-time preview"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/EmbedBG.png"
            },
            {
                title: "Easy Bot Management",
                description: "Manage your Discord bot from our user-friendly dashboard. Monitor performance, update settings, and make changes to your bot in real-time - all from one central location.",
                bulletPoints: [
                    "Intuitive dashboard",
                    "Real-time configuration"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SecurityBG.png"
            },
            {
                title: "Conditional Logic",
                description: "Add smart decision-making to your bot with our visual logic builder. Create if-then conditions, loops, and complex workflows without any programming knowledge.",
                bulletPoints: [
                    "Visual logic editor",
                    "Complex automations"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/LogicBG.png"
            },
            {
                title: "Event System Builder",
                description: "Create automated responses to server events using our visual event builder. Set up welcome messages, member tracking, role assignments, and more without any coding.",
                bulletPoints: [
                    "Drag and drop event builder",
                    "Automated responses"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ScalableInfastructureBG.png"
            },
            {
                title: "Marketplace",
                description: "Browse our extensive marketplace filled with pre-made commands and events. Find and activate the perfect features for your server with just one click, or share your own creations with the community.",
                bulletPoints: [
                    "1000+ commands and events",
                    "One click activation"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/DatabaseBG.png"
            },
            {
                title: "Active Developer Badge",
                description: "Get the exclusive Discord Active Developer Badge in under 5 minutes. No coding required - just enable our module and run a command.",
                bulletPoints: [
                    "One-click setup",
                    "24hour wait time"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/active_dev.png"
            }
        ];

        const secondaryFeatures = [
            {
                title: "Visual Command Builder",
                description: "Create custom Discord bot commands with our intuitive drag-and-drop builder. Design complex interactions without writing any code.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
            },
            {
                title: "Marketplace",
                description: "Browse our extensive marketplace filled with pre-made commands and events. Find and activate the perfect features for your server with just one click, or share your own creations with the community.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/custommodules.png"
            },
            {
                title: "Detailed Documentation",
                description: "Get step-by-step instructions and examples for every feature. Learn how to use our Discord bot maker to its full potential.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/247-hosting.png"
            },
            {
                title: "Custom Variables",
                description: "Create dynamic bot responses with our powerful variable system. Store data, track stats, and personalize messages.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/advanced-dashboard.png"
            },
            {
                title: "Module Library",
                description: "Enhance your bot with our extensive library of pre-built modules. Add features like welcome messages, auto-mod, and more with one click.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/custommodules.png"
            },
            {
                title: "24/7 Hosting",
                description: "Your Discord bot stays online around the clock with our reliable hosting. Focus on creating while we handle the infrastructure.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/botpanelintegration.png"
            }
        ];



        const tutorialSteps = [
            {
                title: "Create a Discord Application",
                description: <>
                    <p>First, visit the <a href="https://discord.com/developers/applications" target="_blank" className="text-[#f45142]">Discord Developer Portal</a> and click the 'New Application' button in the top right.</p>
                    <p className="mt-2">Give your application a name - this can be changed later. Accept the Terms of Service and Developer Policy, then click 'Create'.</p>
                    <p className="mt-2">You'll be taken to the General Information page where you can set your bot's description and tags if desired.</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/create-bot.png"
            },
            {
                title: "Convert to a Bot Application",
                description: <>
                    <p>Click on the 'Bot' tab in the left sidebar menu. This will take you to the Bot page.</p>
                    <p className="mt-2">Click the 'Add Bot' button and confirm by clicking 'Yes, do it!' in the popup.</p>
                    <p className="mt-2">Here you can customize your bot's username and profile picture. Click 'Reset Token' to generate your bot's token - <span className="font-bold text-[#f45142]">never share this token with anyone!</span></p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/bot-token.png"
            },
            {
                title: "Enable Required Permissions",
                description: <>
                    <p>While still on the Bot page, scroll down to 'Privileged Gateway Intents'.</p>
                    <p className="mt-2">Enable all three toggles:</p>
                    <ul className="list-disc list-inside mt-2 ml-4">
                        <li>PRESENCE INTENT</li>
                        <li>SERVER MEMBERS INTENT</li>
                        <li>MESSAGE CONTENT INTENT</li>
                    </ul>
                    <p className="mt-2">Click 'Save Changes' at the bottom. <span className="text-[#f45142]">Important:</span> Do NOT enable 'Requires OAuth2 Code Grant'.</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/add-token.png"
            },
            {
                title: "Connect to BotGhost",
                description: <>
                    <p>Head to the <a href="https://dashboard.botghost.com/dashboard" target="_blank" className="text-[#f45142]">BotGhost Dashboard</a> and log in with Discord if needed.</p>
                    <p className="mt-2">On the New Bot setup page, paste your bot token into the 'Bot Token' field.</p>
                    <p className="mt-2">Click 'Create Bot' to connect your bot to BotGhost. We'll handle all the technical setup automatically.</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/slash-command.png"
            },
            {
                title: "Invite Your Bot",
                description: <>
                    <p>In your BotGhost dashboard, click the 'Invite' button in the left menu.</p>
                    <p className="mt-2">Select the server where you want to add your bot from the dropdown menu.</p>
                    <p className="mt-2">Make sure all the required permissions are selected - these are pre-configured by BotGhost for optimal functionality.</p>
                    <p className="mt-2">Click 'Continue' and then 'Authorize' to add your bot to your server.</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/test-command.png"
            },
            {
                title: "Start Creating",
                description: <>
                    <p>Your bot is now ready! You can:</p>
                    <ul className="list-disc list-inside mt-2 ml-4">
                        <li>Enable pre-built modules from our module library</li>
                        <li>Create custom commands using our drag-and-drop builder</li>
                        <li>Configure welcome messages, auto-moderation, and more</li>
                        <li>Monitor your bot's performance in the dashboard</li>
                    </ul>
                    <p className="mt-2">Type / in your Discord server to see your bot's available commands!</p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/active-badge.png"
            }
        ];

        const faqQuestions = [
            {
                question: "What is the Active Developer Badge?",
                answer: "The Active Developer Badge is a special badge that Discord gives to developers who create and maintain active Discord bots. It appears on your Discord profile and shows that you're an active bot developer in the Discord community."
            },
            {
                question: "How long does it take to get the Active Developer Badge?",
                answer: "After running your first slash command, you need to wait 24 hours before you can claim the Active Developer Badge. Once you've waited 24 hours, you can visit the Active Developer Portal to claim your badge."
            },
            {
                question: "How do I claim the Active Developer Badge?",
                answer: "After waiting 24 hours from your first slash command, visit the Discord Active Developer Portal, select your bot, set up a community server as your support server, and click the claim button. The badge will appear on your profile within a few minutes."
            },
            {
                question: "Do I need to code to get the Active Developer Badge?",
                answer: "No! With BotGhost, you can get the Active Developer Badge without any coding. Simply create your bot, enable our Active Developer Badge module, and run the provided slash command - we handle all the technical details for you."
            },
            {
                question: "Can I get the Active Developer Badge on mobile?",
                answer: "Yes, while you'll need to use a desktop or web browser to set up your bot initially, you can run the required slash command from the Discord mobile app. The badge will show on your profile across all platforms."
            },
            {
                question: "What if I don't have a community server?",
                answer: "You can create a community server by clicking 'Create a Community Server' in the Active Developer Portal. This will allow you to claim your badge and show it off to your community."
            },
            {
                question: "Is it free?",
                answer: "Yes! BotGhost is free to use and you can get the Active Developer Badge for free."
            }
        ];

        return (
            <div>
                <Helmet>
                    <title>Discord Bot Maker | BotGhost</title>
                    <meta name="description" content="Create your own Discord bot without coding using our easy-to-use Discord bot maker. Build custom commands, automate your server, and launch your bot in minutes." />
                </Helmet>

                <NewHomeNav version="v2" style={"no-border"} />
                {/* <MainNav /> */}
                <SeoHeader
                    title={
                        <>
                            The easiest <span className="text-[#f45142]">Discord bot maker</span> - no coding required
                        </>
                    }
                    description="Create your own Discord bot with our intuitive discord bot maker. Design custom commands, automate your server, and launch your bot in minutes - all without writing a single line of code."
                    cta="Create your bot"
                    ctaSecondary="Demo"
                    ctaSecondaryLink="#demo"
                    stats={true}
                />

                <SEOBuilderDemo />

                <DetailedTutorials
                    title={<>How to make a <span className="text-[#f45142]">Discord bot</span> on BotGhost</>}
                    subtitle="Follow these simple steps to create your own Discord bot using our discord bot maker."
                    steps={tutorialSteps}
                    ctaText="Create your bot"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />


                <HeroFeature
                    title={<>Create commands with our <span className="text-white">Discord bot maker</span></>}
                    description="Build powerful Discord bot commands using our easy to use command builder. Drag and drop elements to create custom commands - no coding required."
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
                    bulletPoints={[
                        "Drag-and-drop command builder",
                        "Interactive components & buttons",
                        "Detailed Error Handling",
                        "Complete Documentation"
                    ]}
                    ctaText="Start building commands"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />


                <FeatureSection
                    id="features"
                    title={
                        <>
                            The most powerful <span className="text-[#f45142]">Discord bot maker</span> for your server
                        </>
                    }
                    description="Create your own Discord bot with our intuitive discord bot maker. Design custom commands, automate your server, and launch your bot in minutes - all without writing a single line of code."

                    features={mainFeatures}
                />

                <Testimonial
                    quote="Simple & Customizable; A dream to bot makers who have coding knowledge but are short on time."
                    author="Fluster"
                    role="Discord Bot Maker"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/e443cd1a18680d56fd27f2d286c389e7.png"
                />


                <StatsSection
                    title={<>The most trusted <span className="text-[#f45142]">Discord bot maker</span> platform</>}
                    description="Join millions of users who've created their perfect Discord bot with our powerful bot maker"
                    stats={[
                        {
                            number: "1.5M+",
                            label: "Users",
                            description: "Active users trust our Discord bot maker"
                        },
                        {
                            number: "10M+",
                            label: "Commands Created",
                            description: "Custom commands built by our community"
                        },
                        {
                            number: "100M+",
                            label: "Event Triggers",
                            description: "Automated events handled monthly"
                        },
                        {
                            number: "500K+",
                            label: "Servers",
                            description: "Discord servers powered by our bots"
                        },
                        {
                            number: "2M+",
                            label: "Bots Created",
                            description: "Discord bots made with BotGhost"
                        },
                        {
                            number: "100K+",
                            label: "Active Developers",
                            description: "Discord developers who've claimed their Active Developer Badge"
                        }
                    ]}
                />

                {/* <CTABanner
                    title={<>Ready to build with our <span className="text-[#f45142]">Discord bot maker</span>?</>}
                    subtitle="Create your own custom Discord bot in minutes with our drag-and-drop bot maker"
                    ctaText="Create your bot"
                    img="https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                /> */}

                <RedCTA
                    title={<>Ready to build with our Discord bot maker?</>}
                    subtitle="Create your own custom Discord bot in minutes with our drag-and-drop bot maker"
                    buttonText="Start creating for free"
                />
                <YouTubeSection
                    title="Watch how to make a Discord bot on BotGhost"
                    description="Learn how to make a Discord bot on BotGhost in under 5 minutes"

                // videoId="1aBMOGSdq3E"
                />
                <TutorialsSection />
                <SecondaryFeatures
                    title={
                        <>
                            The most powerful <span className="text-[#f45142]">Discord bot maker</span> for your server
                        </>
                    }
                    description="Build your perfect Discord bot with our drag-and-drop bot maker. Create custom commands, automate tasks, and manage your server with ease."
                    features={secondaryFeatures}
                />
                <CTABanner
                    title="Start building your Discord bot today"
                    highlight="today"
                    subtitle="Get started with our intuitive Discord bot maker"
                    ctaText="Start creating for free"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />
                {/* <FAQ questions={faqQuestions} />

                <TutorialsSection />

                <SecondaryFeatures
                    title={
                        <>
                            The most powerful <span className="text-[#f45142]">Discord bot maker</span> for your server
                        </>
                    }
                    description="Build your perfect Discord bot with our drag-and-drop bot maker. Create custom commands, automate tasks, and manage your server with ease."
                    features={secondaryFeatures}
                />

                <YouTubeSection
                    title="Watch how to get the Active Developer Badge"
                    description="Learn how to get the Active Developer Badge in under 5 minutes"

                    videoId="1aBMOGSdq3E"
                />

                <CTABanner
                    title={<>Ready to get your <span className="text-[#f45142]">Active Developer Badge?</span></>}
                    subtitle="Join thousands of users who've built their perfect Discord bot without coding"
                    ctaText="Start creating for free"
                    img="https://botghost-cdn.s3.us-east-2.amazonaws.com/Discord_Active_Developer_Badge.svg.png"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />
 */}




                {/* <div className="hidden md:block">
                    <SEOBuilderDemo />
                </div> */}

                {/* <FeatureSection
                    id="features"
                    title={
                        <>
                            Powerful Discord Bot Hosting with <span className="text-[#f45142]">endless features</span>

                        </>
                    }
                    description="Discover our comprehensive suite of Discord bot hosting features designed to give you complete control. Create your own commands and events with our drag and drop builder."

                    features={mainFeatures}
                /> */}

                {/* <HomeCommandBuilder /> */}

                {/* <Testimonial
                    quote="BotGhost provides me with a convenient way to build custom bots for myself and various content creators I work for."
                    author="Discord Server Admin"
                    role="Admin for multiple Discord servers"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/SchnifL_pfp_r2.png"
                />


                <TestimonialCollage
                    testimonials={testimonials}
                />




                <ModulesSection />
                <RedCTA
                    title="Ready to power up your Discord server?"
                    subtitle="Join thousands of server owners who trust BotGhost for reliable, feature-rich Discord bot hosting."
                    buttonText="Start hosting for free"
                />
                <TutorialsSection />
                <YouTubeSection
                    title="Watch How It Works"
                    description="Learn how to start hosting your Discord bot on BotGhost"
                />

                <StepByStep
                    title="Simple steps to start hosting your Discord bot on BotGhost"
                    description="Get started with professional Discord bot hosting in minutes"
                />

                <CTABanner
                    title="Start building your Discord bot"
                    highlight="today"
                    subtitle="Get started with professional Discord bot hosting in minutes"
                    ctaText="Start hosting for free"
                    ctaLink="/signup"
                /> */}

            </div>
        );
    }
}

export default DiscordBotMaker;
