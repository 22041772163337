import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ACTION_TYPES } from '../seo_elements/builder_demo/builder_vars';
import {
    faTextHeight,
    faSortNumericDown,
    faUser,
    faHashtag,
    faUsers,
    faQuestion,
    faFile,
    faGreaterThanEqual,
    faLock,
    faPercent,
    faDollarSign
} from '@fortawesome/free-solid-svg-icons';

export class BuilderOptions extends Component {
    render() {
        const { title = "Build your bot with our powerful tools", description = "Create complex Discord bot commands with our visual builder. Choose from a wide range of actions and conditions." } = this.props;

        const optionTypes = [
            { icon: faTextHeight, title: "Text", description: "A plain text option" },
            { icon: faSortNumericDown, title: "Number", description: "A number option" },
            { icon: faUser, title: "User", description: "Select a member from the server" },
            { icon: faHashtag, title: "Channel", description: "Select a channel from the server" },
            { icon: faUsers, title: "Role", description: "Select a role from the server" },
            { icon: faQuestion, title: "Choice", description: "A True or False option" },
            { icon: faFile, title: "Attachment", description: "An attachment option" }
        ];

        const conditionTypes = [
            { icon: faGreaterThanEqual, title: "Comparison Condition", description: "Run actions based on the difference between two values" },
            { icon: faLock, title: "Permissions Condition", description: "Run actions based on the server permissions of a user" },
            { icon: faPercent, title: "Chance Condition", description: "Set a percent chance for actions to run" },
            { icon: faHashtag, title: "Channel Condition", description: "Run actions based on the channel" },
            { icon: faUsers, title: "Role Condition", description: "Run actions based on the roles of the user" },
            { icon: faUser, title: "User Condition", description: "Run actions based on who triggered the action" },
            { icon: faDollarSign, title: "Premium Check Condition", description: "Run actions based on whether the bot is premium or not" }
        ];

        // Group actions by type
        const actionGroups = {
            Options: optionTypes,
            Conditions: conditionTypes,
            Messages: ACTION_TYPES.filter(action =>
                action.value.includes('message') ||
                action.value.includes('text') ||
                action.value.includes('embed') ||
                action.value.includes('response')
            ),
            Moderation: ACTION_TYPES.filter(action =>
                action.value.includes('kick') ||
                action.value.includes('ban') ||
                action.value.includes('timeout') ||
                action.value.includes('purge')
            ),
            Roles: ACTION_TYPES.filter(action =>
                action.value.includes('role')
            ),
            Channels: ACTION_TYPES.filter(action =>
                action.value.includes('channel') ||
                action.value.includes('thread')
            ),
            Variables: ACTION_TYPES.filter(action =>
                action.value.includes('variable') ||
                action.value.includes('currency')
            ),
            Logic: ACTION_TYPES.filter(action =>
                action.value.includes('loop') ||
                action.value.includes('wait') ||
                action.value.includes('error')
            )
        };

        return (
            <div className="bg-[#1E2124] py-24">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl md:text-5xl font-bold text-[#f1f1f1] mb-6">
                            {title}
                        </h2>
                        <p className="text-xl text-[#9B9D9F] max-w-3xl mx-auto">
                            {description}
                        </p>
                    </div>

                    {Object.entries(actionGroups).map(([groupName, items]) => (
                        <div key={groupName} className="mb-16">
                            <h3 className="text-2xl font-bold text-[#f1f1f1] mb-8">{groupName}</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {items.map((item, index) => (
                                    <div
                                        key={index}
                                        className="bg-[#242830] rounded-lg p-6 transform hover:scale-105 transition-all duration-200 hover:bg-[#2A2D35]"
                                    >
                                        <div className="flex items-start space-x-4">
                                            <div className="flex-shrink-0">
                                                <div className="w-10 h-10 rounded-lg bg-[#f45142]/10 flex items-center justify-center">
                                                    <FontAwesomeIcon
                                                        icon={item.icon}
                                                        className="text-[#f45142]"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <h4 className="text-lg font-semibold text-[#f1f1f1] mb-2">
                                                    {item.title}
                                                </h4>
                                                <p className="text-sm text-[#9B9D9F]">
                                                    {item.label || item.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default BuilderOptions; 