import React, { Component } from 'react';

export class Footer extends Component {
    render() {
        const footerLinks = {
            features: {
                title: "Features",
                links: [
                    { name: "Discord Bot Hosting", href: "/discord-bot-hosting" },
                    { name: "Active Developer Badge", href: "/active-developer-badge" },
                    { name: "Discord Bot Maker", href: "/discord-bot-maker" },
                    { name: "Discord Bot Command Builder", href: "/discord-bot-command-builder" },
                    { name: "Discord Bot Event Builder", href: "/discord-bot-event-builder" },
                    { name: "Custom Discord Bot", href: "/custom-discord-bot" },
                    // { name: "Custom Commands", href: "/feautures/custom-commands" },
                    // { name: "Moderation", href: "/features/moderation" },
                    // { name: "Auto Responder", href: "/features/auto-responder" },
                    // { name: "Welcome Messages", href: "/features/welcome-messages" }
                ]
            },
            solutions: {
                title: "Solutions",
                links: [
                    // { name: "Gaming Communities", href: "/solutions/gaming" },
                    // { name: "Content Creators", href: "/solutions/content-creators" },
                    // { name: "Educational", href: "/solutions/educational" },
                    // { name: "Business", href: "/solutions/business" },
                    // { name: "Enterprise", href: "/solutions/enterprise" }
                ]
            },
            tools: {
                title: "Tools",
                links: [
                    { name: "Discord ID Lookup", href: "/tools/discord-user-id-lookup" },
                    { name: "Permissions Calculator", href: "/tools/discord-permissions-calculator" },
                    { name: "Time Converter", href: "/tools/discord-time-converter" },
                    { name: "Embed Builder", href: "/tools/discord-embed-builder" },
                    { name: "Vanity Checker", href: "/tools/discord-vanity-checker" }
                ]
            },
            support: {
                title: "Support",
                links: [
                    { name: "Docs", href: "https://docs.botghost.com" },
                    { name: "Discord", href: "https://discord.gg/botghost" },
                    { name: "Community", href: "https://botghost.com/community/" },
                    { name: "Contact Us", href: "mailto:admin@botghost.com" },
                ]
            },
            resources: {
                title: "Resources",
                links: [
                    { name: "Affiliate Terms", href: "/affiliate-tos" },
                    { name: "Contact", href: "https://discord.gg/botghost" },
                    { name: "Refund Policy", href: "https://docs.botghost.com/premium/refund-and-cancellation-policy" },
                    { name: "Security", href: "/security" },
                    { name: "Privacy Policy", href: "/privacy-policy" },
                    { name: "Terms of Service", href: "/tos" }
                ]
            }
        };

        return (
            <footer className="bg-[#f45142] text-white py-16">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 mb-16">
                        {Object.values(footerLinks).map((section) => (
                            <div key={section.title}>
                                <h4 className="font-bold mb-4 text-lg">{section.title}</h4>
                                <ul className="space-y-2">
                                    {section.links.map((link) => (
                                        <li key={link.name}>
                                            <a
                                                className="text-white hover:underline transition-colors duration-200 text-sm"
                                                href={link.href}
                                            >
                                                {link.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-col md:flex-row justify-between items-center pt-8 border-t border-white/20">
                        <div className="flex flex-col md:flex-row items-center gap-4">
                            <span className="text-sm text-white/80">
                                © {new Date().getFullYear()} BotGhost. All rights reserved.
                            </span>
                            <span className="text-sm text-white/80">
                                ABN: 33 673 683 059
                            </span>
                        </div>
                        <div className="flex space-x-6">
                            <a
                                href="https://discord.gg/botghost"
                                className="text-white hover:!text-white"
                            >
                                Discord
                            </a>
                            <a
                                href="https://www.linkedin.com/company/botghost/"
                                className="text-white hover:!text-white"
                            >
                                LinkedIn
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer; 