import React from 'react';

const TestimonialCollage = ({ testimonials, title }) => {
    // Split testimonials into left and right columns
    const midPoint = Math.ceil(testimonials.length / 2);
    const leftColumn = testimonials.slice(0, midPoint);
    const rightColumn = testimonials.slice(midPoint);

    return (
        <div className="bg-[#242830] py-24">
            <div className="max-w-[1400px] mx-auto px-4">
                {/* Main Title */}
                <div className="text-center mb-20 max-w-4xl mx-auto">
                    <h2 className="text-4xl md:text-6xl font-bold text-white mb-4">
                        {title ? title : <>                        Why <span className="text-[#f45142]">1,500,000+</span> users trust BotGhost<br />
                            for Discord Bot Hosting</>}
                    </h2>
                </div>

                {/* Testimonial Grid */}
                <div className="grid md:grid-cols-2 gap-x-16 gap-y-16 max-w-6xl mx-auto">
                    {/* Left Column */}
                    <div className="space-y-16">
                        {leftColumn.map((testimonial, index) => (
                            <div key={index} className="bg-[#ffffff05] p-8 rounded-lg">
                                <h3 className="text-2xl font-bold text-white mb-2">{testimonial.title}</h3>
                                <p className="text-[#9B9D9F] mb-6">{testimonial.subtitle}</p>
                                <div className="flex items-center gap-2 mb-4">
                                    <div className="text-[#f45142]">★★★★★</div>
                                </div>
                                <p className="text-white italic mb-4">{testimonial.quote}</p>
                                <div className="flex items-center gap-3">
                                    <img
                                        src={testimonial.avatarUrl}
                                        alt="Profile"
                                        className="w-10 h-10 rounded-full object-cover"
                                    />
                                    <p className="text-[#9B9D9F] text-sm">{testimonial.name}, {testimonial.role}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Right Column */}
                    <div className="space-y-16">
                        {rightColumn.map((testimonial, index) => (
                            <div key={index} className="bg-[#ffffff05] p-8 rounded-lg">
                                <h3 className="text-2xl font-bold text-white mb-2">{testimonial.title}</h3>
                                <p className="text-[#9B9D9F] mb-6">{testimonial.subtitle}</p>
                                <div className="flex items-center gap-2 mb-4">
                                    <div className="text-[#f45142]">★★★★★</div>
                                </div>
                                <p className="text-white italic mb-4">{testimonial.quote}</p>
                                <div className="flex items-center gap-3">
                                    <img
                                        src={testimonial.avatarUrl}
                                        alt="Profile"
                                        className="w-10 h-10 rounded-full object-cover"
                                    />
                                    <p className="text-[#9B9D9F] text-sm">{testimonial.name}, {testimonial.role}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCollage; 