import React, { Component } from 'react';

export class FeatureSection extends Component {
    render() {
        const { title, subtitle, features, id = 'features' } = this.props;

        return (
            <div id={id} className="bg-[#1E2124] py-24 scroll-mt-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-5xl md:text-6xl font-bold text-white mb-4 leading-tight">
                            {this.props.title}
                        </h2>
                        <p className="text-[#9B9D9F] text-xl max-w-3xl mx-auto mt-6">
                            {this.props.description}
                        </p>
                    </div>

                    {/* Feature Grid */}
                    <div className="space-y-32">
                        {features.map((feature, index) => (
                            <div key={index} className="grid md:grid-cols-2 gap-16 items-center">
                                <div className={index % 2 === 1 ? "md:order-2" : ""}>
                                    <h3 className="text-2xl font-bold text-[#ffffff] mb-4">
                                        {feature.title}
                                    </h3>
                                    <p className="text-lg text-[#9B9D9F] mb-6">
                                        {feature.description}
                                    </p>
                                    <ul className="space-y-4">
                                        {feature.bulletPoints.map((point, i) => (
                                            <li key={i} className="flex items-start">
                                                <svg className="w-6 h-6 text-[#f45142] mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                </svg>
                                                <span className="text-[#9B9D9F]">{point}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className={index % 2 === 1 ? "md:order-1" : ""}>
                                    <img src={feature.image} alt={feature.title} className="rounded-lg shadow-xl" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default FeatureSection; 