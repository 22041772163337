import React from 'react';

const DocumentationLinks = ({
    title = "Learn more about Discord events",
    description = "Explore our comprehensive documentation and guides to master Discord event automation",
    links = [
        {
            title: "Getting Started with Events",
            description: "Learn the basics of Discord events and how to create your first automation",
            link: "https://docs.botghost.com/events/getting-started",
            category: "Getting Started"
        },
        {
            title: "Event Builder Overview",
            description: "A complete overview of our visual event builder interface and features",
            link: "https://docs.botghost.com/events/builder-overview",
            category: "Getting Started"
        },
        {
            title: "Quick Start Guide",
            description: "Create your first event automation in under 5 minutes",
            link: "https://docs.botghost.com/events/quick-start",
            category: "Getting Started"
        },
        {
            title: "Message Event Guide",
            description: "Comprehensive guide to handling message events, reactions, and content",
            link: "https://docs.botghost.com/events/message-events",
            category: "Popular Guides"
        },
        {
            title: "Member Events Tutorial",
            description: "Learn how to automate member joins, leaves, and role changes",
            link: "https://docs.botghost.com/events/member-events",
            category: "Popular Guides"
        },
        {
            title: "Auto Role Setup",
            description: "Set up automatic role assignments when members join your server",
            link: "https://docs.botghost.com/events/auto-role",
            category: "Popular Guides"
        },
        {
            title: "Custom Welcome Messages",
            description: "Create personalized welcome messages with dynamic content",
            link: "https://docs.botghost.com/events/welcome-messages",
            category: "Popular Guides"
        },
        {
            title: "Server Event Reference",
            description: "Complete reference for all server-wide events and their properties",
            link: "https://docs.botghost.com/events/server-events",
            category: "Reference"
        },
        {
            title: "Event Components",
            description: "Detailed documentation of all available event components and their usage",
            link: "https://docs.botghost.com/events/components",
            category: "Reference"
        },
        {
            title: "Event Triggers List",
            description: "Complete list of all available event triggers and their parameters",
            link: "https://docs.botghost.com/events/triggers",
            category: "Reference"
        },
        {
            title: "Voice Events Guide",
            description: "Tutorial on handling voice channel events and automations",
            link: "https://docs.botghost.com/events/voice-events",
            category: "Advanced Guides"
        },
        {
            title: "Event Variables",
            description: "Learn how to use variables and dynamic data in your events",
            link: "https://docs.botghost.com/events/variables",
            category: "Advanced Guides"
        },
        {
            title: "Conditional Events",
            description: "Create complex event flows with conditions and branching logic",
            link: "https://docs.botghost.com/events/conditions",
            category: "Advanced Guides"
        },
        {
            title: "Event Debugging",
            description: "Learn how to test and debug your event automations",
            link: "https://docs.botghost.com/events/debugging",
            category: "Advanced Guides"
        },
        {
            title: "Event Best Practices",
            description: "Tips and best practices for creating reliable event automations",
            link: "https://docs.botghost.com/events/best-practices",
            category: "Advanced Guides"
        }
    ]
}) => {
    // Group links by category
    const groupedLinks = links.reduce((acc, link) => {
        if (!acc[link.category]) {
            acc[link.category] = [];
        }
        acc[link.category].push(link);
        return acc;
    }, {});

    return (
        <div className="bg-[#242830] py-24">
            <div className="max-w-7xl mx-auto px-4">
                <div className="text-center mb-16">
                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">
                        {title}
                    </h2>
                    <p className="text-xl text-[#9B9D9F] max-w-3xl mx-auto">
                        {description}
                    </p>
                </div>

                <div className="grid gap-8">
                    {Object.entries(groupedLinks).map(([category, categoryLinks]) => (
                        <div key={category} className="mb-8">
                            <h3 className="text-2xl font-bold text-white mb-6">
                                {category}
                            </h3>
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {categoryLinks.map((link, index) => (
                                    <a
                                        key={index}
                                        href={link.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-[#1E2124] p-6 rounded-lg hover:bg-[#2A2D35] transition-all duration-200 group"
                                    >
                                        <h4 className="text-xl font-semibold text-white mb-2 group-hover:text-[#f45142]">
                                            {link.title}
                                            <span className="inline-block ml-2 transform group-hover:translate-x-1 transition-transform duration-200">
                                                →
                                            </span>
                                        </h4>
                                        <p className="text-[#9B9D9F]">
                                            {link.description}
                                        </p>
                                    </a>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="text-center mt-12">
                    <a
                        href="https://docs.botghost.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-[#f45142] hover:text-[#ff6152] transition-colors duration-200"
                    >
                        View full documentation
                        <span className="ml-2">→</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default DocumentationLinks; 