import React, { Component } from 'react';

export class FAQ extends Component {
    state = {
        openIndex: null
    };

    toggleQuestion = (index) => {
        this.setState({ openIndex: this.state.openIndex === index ? null : index });
    };

    render() {
        const { questions } = this.props;
        const { openIndex } = this.state;

        return (
            <div className="bg-[#f45142] py-24">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-6xl font-bold text-white mb-8">FAQs</h2>
                    </div>

                    <div className="max-w-4xl mx-auto space-y-6">
                        {questions.map((item, index) => (
                            <div
                                key={index}
                                className="border-b border-white pb-6"
                            >
                                <button
                                    onClick={() => this.toggleQuestion(index)}
                                    className="w-full text-left text-2xl font-bold text-white flex justify-between items-center transition-colors duration-200"
                                >
                                    {item.question}
                                    <span className="ml-4 text-white">
                                        {this.state.openIndex === index ? '−' : '+'}
                                    </span>
                                </button>
                                {this.state.openIndex === index && (
                                    <p className="mt-6 text-white text-lg">
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQ; 