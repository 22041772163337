import React, { Component } from 'react';

export class SecondaryFeatures extends Component {
    render() {
        const features = this.props.features;

        return (
            <div className="bg-[#242830] py-24">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl md:text-5xl font-bold text-white mb-4 leading-tight">
                            {this.props.title}
                        </h2>
                        <p className="text-[#9B9D9F] text-xl max-w-3xl mx-auto mt-6">
                            {this.props.description}
                        </p>
                    </div>

                    {/* Features Grid */}
                    <div className="grid md:grid-cols-3 gap-16">
                        {features.map((feature, index) => (
                            <div key={index} className="text-center">
                                <div className="mb-6">
                                    <img
                                        src={feature.icon}
                                        alt={feature.title}
                                        className="w-16 h-16 mx-auto"
                                    />
                                </div>
                                <h4 className="text-xl font-bold text-[#ffffff] mb-3">
                                    {feature.title}
                                </h4>
                                <p className="text-[#9B9D9F]">
                                    {feature.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default SecondaryFeatures; 