import React, { Component } from 'react';

export class Testimonial extends Component {
    render() {
        const { quote, author, role, image } = this.props;

        return (
            <div className="bg-[#1E2124] py-24">
                <div className="max-w-4xl mx-auto px-4">
                    <div className="text-center">
                        <img
                            src={image}
                            alt={author}
                            className="w-16 h-16 rounded-full mx-auto mb-8"
                        />
                        <blockquote className="text-2xl text-[#ffffff] font-medium mb-6 leading-relaxed">
                            "{quote}"
                        </blockquote>
                        <div className="text-[#f45142]">
                            <span className="block">— {author}</span>
                            <span className="text-[#9B9D9F] text-sm">{role}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Testimonial; 