import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReactFlowProvider } from 'react-flow-renderer';
import SEOBuilderCanvas from './builder_demo/SEOBuilderCanvas';
import SEOBuilderAside from './builder_demo/SEOBuilderAside';

export class SEOBuilderDemo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            elements: [
                {
                    "id": "root",
                    "type": "root",
                    "data": {
                        "title": "welcome",
                        "description": "asd",
                        "data": {
                            "ephemeral": false,
                            "permissions": {
                                "allowed_roles": [
                                    {
                                        "name": "@everyone",
                                        "id": "everyone"
                                    }
                                ],
                                "banned_roles": [],
                                "banned_channels": [],
                                "banned_users": [],
                                "required_permissions": []
                            },
                            "cooldown": {
                                "type": "disabled",
                                "interval": 1,
                                "interval_type": "minutes"
                            },
                            "handleErrors": true,
                            "errors": [],
                            "validated": true
                        }
                    },
                    "draggable": false,
                    "position": {
                        "x": 0.0006798129644572093,
                        "y": 107
                    },
                    "width": 600,
                    "height": 134,
                    "targetPosition": "top",
                    "sourcePosition": "bottom",
                    "selected": false
                },
                {
                    "id": "error_handler",
                    "type": "error",
                    "position": {
                        "x": 650.0006152176279,
                        "y": 150
                    },
                    "draggable": false,
                    "data": {
                        "node_options": {
                            "title": "Error Handler",
                            "description": "Handle errors that occur during the command execution",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "exclamation-triangle",
                                "icon": [
                                    576,
                                    512,
                                    [],
                                    "f071",
                                    "M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                                ]
                            }
                        },
                        "data": {}
                    },
                    "width": 456,
                    "height": 79,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "source": "option_5_1790_9b68",
                    "target": "root",
                    "type": "step",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "id": "reactflow__edge-option_5_1790_9b68-root"
                },
                {
                    "id": "option_5_1790_9b68",
                    "type": "option",
                    "data": {
                        "node_options": {
                            "title": "User",
                            "description": "Select a member from the server",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "user",
                                "icon": [
                                    448,
                                    512,
                                    [],
                                    "f007",
                                    "M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                                ]
                            }
                        },
                        "data": {
                            "type": "user",
                            "name": "user",
                            "description": "Select a user from the server",
                            "required": false,
                            "validated": true
                        }
                    },
                    "position": {
                        "x": 159.5009437485649,
                        "y": 0
                    },
                    "positionAbsolute": {
                        "x": -13,
                        "y": -104
                    },
                    "z": 1000,
                    "width": 281,
                    "height": 79,
                    "selected": false,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "id": "action_23_f41a_e1a6",
                    "type": "action",
                    "data": {
                        "node_options": {
                            "title": "Plain Text Reply",
                            "description": "Bot replies with a plain text response",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "reply",
                                "icon": [
                                    512,
                                    512,
                                    [],
                                    "f3e5",
                                    "M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z"
                                ]
                            }
                        },
                        "data": {
                            "type": "plain_text",
                            "response": "ertert",
                            "emojis": [],
                            "target": {
                                "reply": true
                            },
                            "validated": true,
                            "linked": true
                        }
                    },
                    "position": {
                        "x": 463.0003244760334,
                        "y": 816
                    },
                    "positionAbsolute": {
                        "x": 350.50013138622535,
                        "y": 816
                    },
                    "z": 1000,
                    "width": 339,
                    "height": 83,
                    "selected": true,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "id": "action_27_1ede_8e4b",
                    "type": "action",
                    "data": {
                        "node_options": {
                            "title": "Direct Message a User",
                            "description": "Bot sends a direct message to a member",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "envelope",
                                "icon": [
                                    512,
                                    512,
                                    [],
                                    "f0e0",
                                    "M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                                ]
                            }
                        },
                        "data": {
                            "type": "dm_response",
                            "response_options": {
                                "response": "t",
                                "emojis": []
                            },
                            "target": {
                                "self": true
                            },
                            "validated": true,
                            "linked": true,
                            "editId": "1737412926691_97628"
                        }
                    },
                    "position": {
                        "x": 120.50081592887007,
                        "y": 558
                    },
                    "positionAbsolute": {
                        "x": 601.01493457025,
                        "y": 646.9603865391518
                    },
                    "z": 1000,
                    "width": 359,
                    "height": 79,
                    "selected": false,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "id": "action_29_e602_8252",
                    "type": "action",
                    "data": {
                        "node_options": {
                            "title": "React to a Message",
                            "description": "React to a message",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "thumbs-up",
                                "icon": [
                                    512,
                                    512,
                                    [],
                                    "f164",
                                    "M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"
                                ]
                            }
                        },
                        "data": {
                            "type": "react_message",
                            "reactions": [
                                "😀"
                            ],
                            "additionalReactions": "",
                            "target": {
                                "action": true,
                                "editId": "1737412935135_85299"
                            },
                            "validated": true,
                            "linked": true,
                            "action": "action_27_6875_e8e7"
                        }
                    },
                    "position": {
                        "x": 174.50085348312257,
                        "y": 687
                    },
                    "width": 251,
                    "height": 79,
                    "targetPosition": "top",
                    "sourcePosition": "bottom",
                    "selected": false
                },
                {
                    "source": "action_27_1ede_8e4b",
                    "sourceHandle": "action",
                    "target": "action_29_e602_8252",
                    "targetHandle": "action",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "type": "custom",
                    "id": "reactflow__edge-action_27_1ede_8e4baction-action_29_e602_8252action"
                },
                {
                    "id": "action_33_dd25_e142",
                    "type": "action",
                    "data": {
                        "node_options": {
                            "title": "Add Roles",
                            "description": "Adds one or more roles to a user",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "user-plus",
                                "icon": [
                                    640,
                                    512,
                                    [],
                                    "f234",
                                    "M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                                ]
                            }
                        },
                        "data": {
                            "roles": [],
                            "validated": true,
                            "type": "role_add",
                            "additional_roles": "",
                            "success_handles": false,
                            "target": {
                                "self": true
                            },
                            "server_id": "",
                            "linked": true
                        }
                    },
                    "position": {
                        "x": 478.5003716861409,
                        "y": 945
                    },
                    "width": 304,
                    "height": 79,
                    "targetPosition": "top",
                    "sourcePosition": "bottom",
                    "selected": false
                },
                {
                    "source": "action_23_f41a_e1a6",
                    "sourceHandle": "action",
                    "target": "action_33_dd25_e142",
                    "targetHandle": "action",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "type": "custom",
                    "id": "reactflow__edge-action_23_f41a_e1a6action-action_33_dd25_e142action"
                },
                {
                    "id": "action_27_6875_e8e7",
                    "type": "action",
                    "data": {
                        "node_options": {
                            "title": "Send a Message with attached Buttons",
                            "description": "Add buttons and button actions",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "mouse-pointer",
                                "icon": [
                                    320,
                                    512,
                                    [],
                                    "f245",
                                    "M302.189 329.126H196.105l55.831 135.993c3.889 9.428-.555 19.999-9.444 23.999l-49.165 21.427c-9.165 4-19.443-.571-23.332-9.714l-53.053-129.136-86.664 89.138C18.729 472.71 0 463.554 0 447.977V18.299C0 1.899 19.921-6.096 30.277 5.443l284.412 292.542c11.472 11.179 3.007 31.141-12.5 31.141z"
                                ]
                            }
                        },
                        "data": {
                            "type": "button_response",
                            "ephemeral": false,
                            "response_options": {
                                "response": "t",
                                "target": {
                                    "reply": true
                                },
                                "emojis": []
                            },
                            "validated": true,
                            "linked": true,
                            "editId": "1737412935135_85299"
                        }
                    },
                    "position": {
                        "x": 529.5005583070571,
                        "y": 558
                    },
                    "positionAbsolute": {
                        "x": 533.9502721836898,
                        "y": 559.7708170937206
                    },
                    "z": 1000,
                    "width": 412,
                    "height": 79,
                    "selected": false,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "id": "button_28_147b_a390",
                    "type": "button",
                    "data": {
                        "node_options": {
                            "label": "Button",
                            "style": "PRIMARY",
                            "emoji_id": ""
                        },
                        "data": {
                            "label": "Verify User",
                            "style": "SUCCESS",
                            "emoji_id": ""
                        }
                    },
                    "draggable": true,
                    "position": {
                        "x": 580.5003503066937,
                        "y": 687
                    },
                    "positionAbsolute": {
                        "x": 461.9502721836898,
                        "y": 722.7708170937206
                    },
                    "z": 1000,
                    "width": 100,
                    "height": 32,
                    "selected": false,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "source": "action_27_6875_e8e7",
                    "target": "button_28_147b_a390",
                    "type": "step",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "id": "reactflow__edge-action_27_6875_e8e7-button_28_147b_a390"
                },
                {
                    "source": "button_28_147b_a390",
                    "sourceHandle": "action",
                    "target": "action_23_f41a_e1a6",
                    "targetHandle": "action",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "type": "custom",
                    "id": "reactflow__edge-button_28_147b_a390action-action_23_f41a_e1a6action"
                },
                {
                    "id": "button_32_4ad0_35b6",
                    "type": "button",
                    "data": {
                        "node_options": {
                            "label": "Button",
                            "style": "PRIMARY",
                            "emoji_id": ""
                        },
                        "data": {
                            "label": "Kick User",
                            "style": "DANGER",
                            "emoji_id": ""
                        }
                    },
                    "draggable": true,
                    "position": {
                        "x": 954.5004019653433,
                        "y": 687
                    },
                    "positionAbsolute": {
                        "x": 803.9502721836898,
                        "y": 694.7708170937206
                    },
                    "z": 1000,
                    "width": 91,
                    "height": 32,
                    "selected": false,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "source": "action_27_6875_e8e7",
                    "target": "button_32_4ad0_35b6",
                    "type": "step",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "id": "reactflow__edge-action_27_6875_e8e7-button_32_4ad0_35b6"
                },
                {
                    "id": "action_31_78e9_12e6",
                    "type": "action",
                    "data": {
                        "node_options": {
                            "title": "Kick a Member",
                            "description": "Kick a member from the server",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "user-slash",
                                "icon": [
                                    640,
                                    512,
                                    [],
                                    "f506",
                                    "M633.8 458.1L362.3 248.3C412.1 230.7 448 183.8 448 128 448 57.3 390.7 0 320 0c-67.1 0-121.5 51.8-126.9 117.4L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4l588.4 454.7c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.4-6.8 4.1-16.9-2.9-22.3zM96 422.4V464c0 26.5 21.5 48 48 48h350.2L207.4 290.3C144.2 301.3 96 356 96 422.4z"
                                ]
                            }
                        },
                        "data": {
                            "type": "kick_action",
                            "target": "trtert",
                            "reason": "t",
                            "validated": true,
                            "success_handles": false,
                            "linked": true
                        }
                    },
                    "position": {
                        "x": 853.5003606820568,
                        "y": 816
                    },
                    "width": 293,
                    "height": 79,
                    "selected": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "source": "button_32_4ad0_35b6",
                    "sourceHandle": "action",
                    "target": "action_31_78e9_12e6",
                    "targetHandle": "action",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "type": "custom",
                    "id": "reactflow__edge-button_32_4ad0_35b6action-action_31_78e9_12e6action"
                },
                {
                    "id": "action_35_52b4_5dfb",
                    "type": "action",
                    "data": {
                        "node_options": {
                            "title": "Plain Text Reply",
                            "description": "Bot replies with a plain text response",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "reply",
                                "icon": [
                                    512,
                                    512,
                                    [],
                                    "f3e5",
                                    "M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z"
                                ]
                            }
                        },
                        "data": {
                            "type": "plain_text",
                            "response": "t",
                            "emojis": [],
                            "target": {
                                "reply": true
                            },
                            "validated": true,
                            "linked": true
                        }
                    },
                    "position": {
                        "x": 832.5007906439233,
                        "y": 945
                    },
                    "positionAbsolute": {
                        "x": 798.651246108743,
                        "y": 957.0886442015737
                    },
                    "z": 1000,
                    "width": 335,
                    "height": 79,
                    "selected": false,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "source": "action_31_78e9_12e6",
                    "sourceHandle": "action",
                    "target": "action_35_52b4_5dfb",
                    "targetHandle": "action",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "type": "custom",
                    "id": "reactflow__edge-action_31_78e9_12e6action-action_35_52b4_5dfbaction"
                },
                {
                    "id": "$else_21_ce21_a4fa",
                    "type": "conditionChild",
                    "data": {
                        "node_options": {
                            "type": "role"
                        },
                        "data": {
                            "type": "else"
                        }
                    },
                    "draggable": true,
                    "position": {
                        "x": 7.500165594427072,
                        "y": 429
                    },
                    "positionAbsolute": {
                        "x": 349.9035599449194,
                        "y": 423.28944875958797
                    },
                    "z": 1000,
                    "width": 124,
                    "height": 79,
                    "selected": false,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "source": "condition_21_02d3_61e4",
                    "target": "$else_21_ce21_a4fa",
                    "type": "step",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "id": "reactflow__edge-condition_21_02d3_61e4-$else_21_ce21_a4fa"
                },
                {
                    "id": "condition_21_02d3_61e4",
                    "type": "condition",
                    "data": {
                        "node_options": {
                            "title": "Role Condition",
                            "description": "Run actions based on the roles of the user.",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "users",
                                "icon": [
                                    640,
                                    512,
                                    [],
                                    "f0c0",
                                    "M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                                ]
                            }
                        },
                        "data": {
                            "type": "condition",
                            "condition_type": "role",
                            "multiple": false,
                            "target": {
                                "self": true
                            },
                            "validated": true,
                            "linked": true
                        }
                    },
                    "position": {
                        "x": 114.50053575699457,
                        "y": 300
                    },
                    "positionAbsolute": {
                        "x": 762.9035599449194,
                        "y": 320.28944875958797
                    },
                    "z": 1000,
                    "width": 371,
                    "height": 79,
                    "selected": false,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "source": "root",
                    "sourceHandle": "action",
                    "target": "condition_21_02d3_61e4",
                    "targetHandle": "action",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "type": "custom",
                    "id": "reactflow__edge-rootaction-condition_21_02d3_61e4action"
                },
                {
                    "id": "conChild25_rmmmvaafj",
                    "type": "conditionChild",
                    "data": {
                        "node_options": {
                            "type": "role"
                        },
                        "data": {
                            "id": "936327383919784053",
                            "name": "admin",
                            "option": "channel",
                            "not": false
                        }
                    },
                    "draggable": true,
                    "position": {
                        "x": 619.5008887676303,
                        "y": 429
                    },
                    "width": 232,
                    "height": 79,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "source": "condition_21_02d3_61e4",
                    "target": "conChild25_rmmmvaafj",
                    "type": "step",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "id": "reactflow__edge-condition_21_02d3_61e4-conChild25_rmmmvaafj"
                },
                {
                    "id": "conChild27_1rabdqwi6",
                    "type": "conditionChild",
                    "data": {
                        "node_options": {
                            "type": "role"
                        },
                        "data": {
                            "id": "1040915370866901055",
                            "name": "Test",
                            "option": "channel",
                            "not": false
                        }
                    },
                    "draggable": true,
                    "position": {
                        "x": 181.50061615275922,
                        "y": 429
                    },
                    "positionAbsolute": {
                        "x": 1046.9035599449194,
                        "y": 412.28944875958797
                    },
                    "z": 1000,
                    "width": 237,
                    "height": 79,
                    "selected": false,
                    "dragging": false,
                    "targetPosition": "top",
                    "sourcePosition": "bottom"
                },
                {
                    "source": "condition_21_02d3_61e4",
                    "target": "conChild27_1rabdqwi6",
                    "type": "step",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "id": "reactflow__edge-condition_21_02d3_61e4-conChild27_1rabdqwi6"
                },
                {
                    "source": "conChild25_rmmmvaafj",
                    "sourceHandle": "action",
                    "target": "action_27_6875_e8e7",
                    "targetHandle": "action",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "type": "custom",
                    "id": "reactflow__edge-conChild25_rmmmvaafjaction-action_27_6875_e8e7action"
                },
                {
                    "source": "conChild27_1rabdqwi6",
                    "sourceHandle": "action",
                    "target": "action_27_1ede_8e4b",
                    "targetHandle": "action",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "type": "custom",
                    "id": "reactflow__edge-conChild27_1rabdqwi6action-action_27_1ede_8e4baction"
                },
                {
                    "id": "action_31_25cb_c01c",
                    "type": "action",
                    "data": {
                        "node_options": {
                            "title": "Embed Reply",
                            "description": "Bot replies with an embed response",
                            "icon": {
                                "prefix": "fas",
                                "iconName": "code-branch",
                                "icon": [
                                    384,
                                    512,
                                    [],
                                    "f126",
                                    "M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z"
                                ]
                            }
                        },
                        "data": {
                            "type": "embed",
                            "embed": {
                                "title": "sdf",
                                "description": "sdf"
                            },
                            "target": {
                                "reply": true
                            },
                            "emojis": [],
                            "variable": "",
                            "validated": true,
                            "linked": true
                        }
                    },
                    "position": {
                        "x": 761.3327604938627,
                        "y": 313.0936643991991
                    },
                    "positionAbsolute": {
                        "x": 761.3327604938627,
                        "y": 313.0936643991991
                    },
                    "z": 1000,
                    "width": 326,
                    "height": 79,
                    "selected": false,
                    "dragging": false
                },
                {
                    "source": "error_handler",
                    "sourceHandle": "action",
                    "target": "action_31_25cb_c01c",
                    "targetHandle": "action",
                    "animated": false,
                    "arrowHeadType": "arrowclosed",
                    "type": "custom",
                    "id": "reactflow__edge-error_handleraction-action_31_25cb_c01caction"
                }
            ],
            selected: null,
            index: 0,
            hidden: false,
            highlightAction: null,
            mode: "default",
            module_id: null,
            premium: false
        };
    }
    render() {
        return (
            <ReactFlowProvider>
                {/* Add invisible overlay div for scrolling */}

                <div className='relative bg-[#222527] h-[850px] overflow-hidden' id="demo">

                    <div className="absolute w-[400px] top-12 right-12 z-10 text-right">
                        <p className="text-white text-5xl font-bold mb-2">
                            {
                                this.props.title ? this.props.title : <>Try out our builder to <span className="text-[#f45142]">create your own command</span></>
                            }
                        </p>
                        <p className="text-white text-lg">
                            {
                                this.props.description ? this.props.description : "Drag and drop option, action and condition blocks to create your own command."
                            }
                        </p>
                    </div>

                    {/* Bottom Right Button to reset the canvas */}
                    <div className="absolute bottom-8 right-8 z-10">
                        <button className="bg-[#f45142] text-white font-bold px-4 py-2 rounded-md" onClick={() => {
                            this.setState({
                                elements: [
                                    {
                                        "id": "root",
                                        "type": "root",
                                        "data": {
                                            "title": "your-command",
                                            "description": "asd",
                                            "data": {
                                                "ephemeral": false,
                                                "permissions": {
                                                    "allowed_roles": [
                                                        {
                                                            "name": "@everyone",
                                                            "id": "everyone"
                                                        }
                                                    ],
                                                    "banned_roles": [],
                                                    "banned_channels": [],
                                                    "banned_users": [],
                                                    "required_permissions": []
                                                },
                                                "cooldown": {
                                                    "type": "disabled",
                                                    "interval": 1,
                                                    "interval_type": "minutes"
                                                },
                                                "handleErrors": true,
                                                "errors": [],
                                                "validated": true
                                            }
                                        },
                                        "draggable": false,
                                        "position": {
                                            "x": 0.0006798129644572093,
                                            "y": 107
                                        },
                                        "width": 600,
                                        "height": 134,
                                        "targetPosition": "top",
                                        "sourcePosition": "bottom",
                                        "selected": false
                                    },
                                ]
                            });
                        }}>
                            Reset Command
                        </button>
                    </div>

                    <div className="absolute inset-0">
                        <SEOBuilderAside />
                        <SEOBuilderCanvas
                            setElements={(elements) => {
                                this.setState({ elements });
                            }}
                            setSelected={() => { }}
                            updateNode={() => { }}
                            updateElementData={() => { }}
                            elements={this.state.elements}
                        />
                    </div>
                </div>
            </ReactFlowProvider>
        );
    }
}

export default SEOBuilderDemo;