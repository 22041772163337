import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import NewHomeNav from '../elements/NewHomeNav';
import SeoHeader from '../seo_elements/SeoHeader';
import FeatureSection from '../seo_elements/FeatureSection';
import SecondaryFeatures from '../seo_elements/SecondaryFeatures';
import Testimonial from '../seo_elements/Testimonial';
import CTABanner from '../seo_elements/CTABanner';
import FAQ from '../seo_elements/FAQ';
import TestimonialCollage from '../seo_elements/TestimonialCollage';
import StepByStep from '../seo_elements/StepByStep';
import YouTubeSection from '../seo_elements/YouTubeSection';
import MainNav from '../elements/MainNav';
import ModulesSection from '../seo_elements/ModulesSection';
import RedCTA from '../seo_elements/RedCTA';
import HomeCommandBuilder from '../elements/HomeCommandBuilder';
import TutorialsSection from '../seo_elements/TutorialsSection';
import SEOBuilderDemo from '../seo_elements/SEOBuilderDemo';
import HeroFeature from '../seo_elements/HeroFeature';
import DocumentationLinks from '../seo_elements/DocumentationLinks';

export class CustomDiscordBot extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {

        const mainFeatures = [
            {
                title: "Make Your Own Discord Bot",
                description: "Create a custom Discord bot in minutes with our easy-to-use bot maker. No coding required - just drag and drop to build your perfect Discord bot.",
                bulletPoints: [
                    "Easy drag-and-drop builder",
                    "Custom bot commands",
                    "Visual bot designer"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/HassleFreeHostBG.png"
            },
            {
                title: "Custom Discord Commands",
                description: "Make your Discord bot unique with custom commands. Create welcome messages, moderation tools, mini-games, and any other command your server needs.",
                bulletPoints: [
                    "Custom slash commands",
                    "Interactive buttons & menus",
                    "Personalized responses"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
            },
            {
                title: "Discord Bot Events",
                description: "Make your Discord bot respond to server events automatically. Create custom welcome messages, role assignments, moderation actions, and more.",
                bulletPoints: [
                    "Custom event handling",
                    "Automated bot responses",
                    "Server management"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/OneClickDeployBG.png"
            },
            {
                title: "Custom Bot Features",
                description: "Add powerful features to your Discord bot. Create custom moderation tools, role management systems, ticket support, and more - all without coding.",
                bulletPoints: [
                    "Custom moderation tools",
                    "Role management system"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SecurityBG.png"
            },
            {
                title: "Automate your server",
                description: "Automate your Discord server with our discord event builder. Create custom moderation tools, role management systems, ticket support, and more - all without coding.",
                bulletPoints: [
                    "Automate your server",
                    "Drag and drop builder"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SecurityBG.png"
            },
            {
                title: "Custom Bot Dashboard",
                description: "Manage your Discord bot with an easy-to-use dashboard. Monitor your bot's performance, customize settings, and make changes to your bot in real-time.",
                bulletPoints: [
                    "Real-time bot management",
                    "Easy configuration"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/DatabaseBG.png"
            }
        ];

        const secondaryFeatures = [
            {
                title: "Custom Command Builder",
                description: "Create custom Discord bot commands with our visual builder. Design unique responses, add buttons, and create interactive experiences.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
            },
            {
                title: "Custom Bot Events",
                description: "Design custom event responses for your Discord bot. Set up welcome messages, member tracking, and server automation.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/247-hosting.png"
            },
            {
                title: "Custom Moderation Tools",
                description: "Build custom moderation features for your Discord bot. Create warning systems, auto-moderation, and custom punishment workflows.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/advanced-dashboard.png"
            },
            {
                title: "Custom Role Management",
                description: "Create custom role management systems for your Discord bot. Set up reaction roles, auto-roles, role menus, and temporary roles.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/custommodules.png"
            },
            {
                title: "Custom Bot Dashboard",
                description: "Create a custom web dashboard for your Discord bot. Let server admins configure your bot through an intuitive interface.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/botpanelintegration.png"
            },
            {
                title: "Custom Bot Features",
                description: "Add custom features to your Discord bot. Create games, utilities, ticket systems, and any other feature you can imagine.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/active_dev.png"
            },
        ];

        const faqQuestions = [
            {
                question: "How to make a custom bot on Discord?",
                answer: "Making a custom Discord bot is easy with BotGhost: 1) Create a free account 2) Click 'Create New Bot' 3) Use our visual builder to design your bot's features 4) Add custom commands and automations 5) Click 'Deploy' to make your bot live. No coding knowledge needed - our drag-and-drop builder lets you create any bot you can imagine!"
            },
            {
                question: "How to make a custom Discord bot?",
                answer: "To make a custom Discord bot: 1) Sign up at BotGhost.com 2) Create your bot application in Discord Developer Portal (we'll guide you) 3) Use our visual builder to add commands, events, and automations 4) Customize your bot's responses and behavior 5) Add your bot to your server. Our platform handles all the technical details so you can focus on creating the perfect bot for your needs."
            },
            {
                question: "How to add custom bot to Discord server?",
                answer: "Adding your custom bot to a Discord server is simple: 1) In your BotGhost dashboard, click 'Invite Bot' 2) Select the server where you want to add your bot 3) Choose the permissions your bot needs 4) Click 'Authorize'. Your bot will instantly join your server and be ready to use. Make sure you have 'Manage Server' permissions in the Discord server where you want to add the bot."
            },
            {
                question: "How to make custom bot Discord?",
                answer: "Creating a custom Discord bot with BotGhost requires no coding: 1) Use our drag-and-drop builder to create commands 2) Set up automated responses and actions 3) Add moderation tools, welcome messages, and custom features 4) Configure role management and permissions 5) Test your bot in real-time. Our platform includes pre-built modules for common features while allowing full customization for unique needs."
            },
            {
                question: "What features can I add to my custom Discord bot?",
                answer: "You can add numerous features to your custom Discord bot: 1) Welcome messages and member screening 2) Auto-role assignment 3) Custom commands and slash commands 4) Moderation tools and auto-moderation 5) Server statistics and member tracking 6) Music playback and entertainment features 7) Custom embed messages 8) Reaction roles 9) Ticket systems 10) And much more! If you can think of it, you can build it with our bot maker."
            },
            {
                question: "Is my data secure?",
                answer: "Yes, we use industry-standard encryption and follow strict privacy policies. Learn more at https://botghost.com/security and https://botghost.com/privacy-policy."
            }
        ];



        return (
            <div>
                <Helmet>
                    <title>Custom Discord Bot | BotGhost</title>
                    <meta name="description" content="Create your own custom Discord bot with our easy-to-use Discord bot maker. Design custom commands, automate your server, and launch your bot in minutes - no coding required." />
                </Helmet>

                <NewHomeNav version="v2" style={"no-border"} />
                {/* <MainNav /> */}
                <SeoHeader
                    title={
                        <>
                            Create Your Own <span className="text-[#f45142]">Custom Discord Bot</span>
                        </>
                    }
                    description="Design, build and host your custom Discord bot with our powerful bot maker. No coding required."
                    cta="Start building for free"
                    ctaSecondary="View features"
                    ctaSecondaryLink="#features"
                    stats={true}
                />

                <HeroFeature
                    title={<>Design Your Own <span className="text-white">Discord Bot</span></>}
                    description="Create a custom Discord bot that perfectly matches your server's needs. No coding required."
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
                    bulletPoints={[
                        "Custom commands and events",
                        "Server automation",
                        "Moderation tools",
                        "Welcome messages"
                    ]}
                    ctaText="Start building your bot"
                />


                <FeatureSection
                    id="features"
                    title={
                        <>
                            Build Your Perfect <span className="text-[#f45142]">Discord Bot</span>
                        </>
                    }
                    description="Create a custom Discord bot tailored to your server's needs. From welcome messages to moderation, build the perfect bot with our visual builder."
                    features={mainFeatures}
                />

                {/* <HomeCommandBuilder /> */}

                <Testimonial
                    quote="BotGhost is an amazing and easy to use service to create your own Discord bot. This has really helped my server!"
                    author="Maksimsz"
                    role="Server Owner"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/PFP.jpg"
                />

                <div className="hidden md:block">
                    <SEOBuilderDemo />
                </div>

                {/* <TestimonialCollage
                    title={<>Over 2M+ Custom Discord Bots Created</>}
                    testimonials={testimonials}
                /> */}

                <FAQ questions={faqQuestions} />

                <SecondaryFeatures
                    title={
                        <>
                            Everything you need to host a <span className="text-[#f45142]">powerful Discord bot</span>. No coding required.
                        </>
                    }
                    description="Create and host your custom Discord bot with your own custom commands and events."
                    features={secondaryFeatures}
                />
                <ModulesSection />
                <RedCTA
                    title="Ready to create your custom Discord bot?"
                    subtitle="Join thousands of users who've created their perfect Discord bot with our easy-to-use bot maker."
                    buttonText="Start building for free"
                />
                <TutorialsSection />
                <YouTubeSection
                    title="Watch How It Works"
                    description="Learn how to start hosting your Discord bot on BotGhost"
                />

                <StepByStep
                    title="Create your dream Discord bot"
                    description="Get started with our easy-to-use Discord bot maker. Design custom commands, automate your server, and launch your bot in minutes - no coding required."
                />

                <CTABanner
                    title="Start building your Discord bot"
                    highlight="today"
                    subtitle="Get started with professional Discord bot hosting in minutes"
                    ctaText="Start hosting for free"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />



                <DocumentationLinks
                    title="Learn How to Create Your Discord Bot"
                    description="Explore our guides and tutorials to build the perfect custom Discord bot"
                    links={[
                        {
                            title: "Getting Started",
                            description: "Learn how to create your first bot on BotGhost",
                            link: "https://docs.botghost.com/getting-started/create-your-first-bot",
                            category: "Getting Started"
                        },
                        {
                            link: "https://docs.botghost.com/getting-started/invite-your-bot",
                            title: "Invite Your Bot",
                            description: "Learn how to invite your bot to your Discord server",
                            category: "Getting Started"
                        },
                        {
                            link: "https://docs.botghost.com/support/frequently-asked-questions",
                            title: "FAQs",
                            description: "Frequently asked questions about BotGhost. Some best practices to follow when creating your own Discord bot.",
                            category: "Getting Started"
                        },
                        {
                            link: "https://docs.botghost.com/general-settings-and-collaboration/collaboration",
                            title: "Collaboration",
                            description: "Learn how to collaborate with other users on BotGhost",
                            category: "General Guides"
                        },
                        {
                            link: "https://docs.botghost.com/custom-commands-and-events/command-and-event-builder",
                            title: "Command and Event Builder",
                            description: "Learn how to use our visual builder to create powerful automations",
                            category: "General Guides"
                        },
                        {
                            link: "https://docs.botghost.com/custom-commands-and-events/data-storage",
                            title: "Data Storage",
                            description: "Learn how to store and retrieve data for your bot's commands and events",
                            category: "General Guides"
                        }
                        // ... add more relevant documentation links
                    ]}
                />

            </div>
        );
    }
}

export default CustomDiscordBot;
