import React, { Component } from 'react';

export class YouTubeSection extends Component {
    render() {
        const videoId = "zXeg8JJyt0k";
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;

        return (
            <div className="bg-[#1E2124] py-24">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl font-bold text-white mb-4">
                            {this.props.title ? this.props.title : "Watch How It Works"}
                        </h2>
                        <p className="text-[#9B9D9F] text-lg">
                            {this.props.description ? this.props.description : "Learn how to create and manage your Discord bot"}
                        </p>
                    </div>

                    <div className="max-w-4xl mx-auto">
                        <div className="relative pb-[56.25%] h-0 rounded-lg overflow-hidden">
                            <iframe
                                className="absolute top-0 left-0 w-full h-full"
                                src={this.props.videoId ? `https://www.youtube.com/embed/${this.props.videoId}` : embedUrl}
                                title="BotGhost Tutorial"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>

                    <div className="text-center mt-12">
                        <a
                            href="https://youtube.com/@BotGhost"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center text-[#f45142] hover:text-[#ff6152] transition-colors duration-200 text-lg font-semibold"
                        >
                            Subscribe to our YouTube channel
                            <span className="ml-2">→</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default YouTubeSection; 