import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const features = [
    { name: "Discord Bot Hosting", href: "/discord-bot-hosting" },
    { name: "Active Developer Badge", href: "/active-developer-badge" },
    { name: "Discord Bot Maker", href: "/discord-bot-maker" },
    { name: "Discord Bot Command Builder", href: "/discord-bot-command-builder" },
    { name: "Discord Bot Event Builder", href: "/discord-bot-event-builder" },
    { name: "Custom Discord Bot", href: "/custom-discord-bot" },
    // { name: "Discord Bot Builder", href: "/features/discord-bot-builder" },
    // { name: "Discord Bot Dashboard", href: "/features/discord-bot-dashboard" },
    // { name: "Discord Bot Analytics", href: "/features/discord-bot-analytics" },
    // { name: "Discord Bot Analytics", href: "/features/discord-bot-analytics" },
];
const modules = [
    { name: 'Reaction Roles', href: '/modules/reaction-roles' },
    { name: 'Roblox', href: '/modules/roblox' },
    { name: 'ChatGPT', href: '/modules/chatgpt' },
    { name: "Global Chat", href: "/modules/global-chat" },
    { name: "Counting", href: "/modules/counting" },
    { name: "Welcome Banners", href: "/modules/welcome-banners" },
    { name: "Verification", href: "/modules/verification" },
    { name: "Auto React", href: "/modules/auto-react" },
    { name: "Automod", href: "/modules/discord-automod" },
    { name: "Sticky Messages", href: "/modules/sticky-messages" },
    { name: "Image Generation", href: "/modules/image-generation" },
];

const support = [
    { name: 'Support Server', href: 'https://discord.com/invite/9UPM6S4xyA' },
    { name: "Documentation", href: "https://docs.botghost.com" },
    { name: "Guides", href: "https://botghost.com/community/" },
];

const tools = [
    { name: "User ID Lookup", href: "/tools/discord-user-id-lookup" },
    { name: "Server Invite Checker", href: "/tools/discord-invite-checker" },
    { name: "Permission Calculator", href: "/tools/discord-permissions-calculator" },
    { name: "Discord Time Converter", href: "/tools/discord-time-converter" },
    { name: "Discord Embed Builder", href: "/tools/discord-embed-builder" },
    { name: "Discord Vanity Checker", href: "/tools/discord-vanity-checker" },
    { name: "Hex to Decimal Converter", href: "/tools/hex-to-decimal" },
];

export class NewHomeNav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false
        };
    }
    showMenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };
    render() {
        return (
            <div className={`home-mobile-container ${this.props.style == "no-border" ? "border-b-0 !bg-[#1E2124]" : ""} ${this.state.menuOpen == true ? "home-mobile-open mobile-open" : null}`} >
                <header className="flex-center">

                    <div className="header-item">
                        <Link className="header-item" to="/">
                            <div className="header-logo home-logo">
                                <img src={process.env.PUBLIC_URL + '/img/logo-red.png'}></img>

                            </div>
                            <span className="logo-text">BotGhost</span>
                        </Link>
                    </div>

                    <div className="header-item header-item-right">
                        <nav className="dash-header-menu home-menu">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <Popover className="relative">
                                    <Popover.Button className="hover:text-red inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
                                        <li><a className='flex flex-row !pr-1'>Features </a> <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /></li>
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-1/2 px-4">
                                            <div className="w-56 shrink rounded-xl bg-darkGray p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                                {features.map((item) => (
                                                    <Link key={item.name} to={item.href} className="block p-2 hover:text-indigo-600">
                                                        {item.name}
                                                    </Link>
                                                ))}
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </Popover>
                                <Popover className="relative">
                                    <Popover.Button className="hover:text-red inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
                                        <li><a className='flex flex-row !pr-1'>Prebuilt Modules </a> <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /></li>

                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className=" absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-1/2 px-4">
                                            <div className="w-56 shrink rounded-xl bg-darkGray p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                                {modules.map((item) => (
                                                    <Link key={item.name} to={item.href} className="block p-2 hover:text-indigo-600">
                                                        {item.name}
                                                    </Link>
                                                ))}
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </Popover>
                                <li><a href="https://botghost.com/market">Market</a></li>
                                <li><Link to="/affiliate">Affiliate Program</Link></li>
                                {/* <li><a href="https://botghost.com/community/" target="_blank">Guides</a></li> */}
                                <Popover className="relative">
                                    <Popover.Button className="hover:text-red inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
                                        <li><a className='flex flex-row !pr-1'>Discord Tools </a> <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /></li>

                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className=" absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-1/2 px-4">
                                            <div className="w-56 shrink rounded-xl bg-darkGray p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                                {tools.map((item) => (
                                                    <Link key={item.name} to={item.href} className="block p-2 hover:text-indigo-600">
                                                        {item.name}
                                                    </Link>
                                                ))}
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </Popover>
                                {/* <li><Link to="/partners">Partner Program</Link></li> */}
                                {/* <li><a href="https://docs.botghost.com">Documentation</a></li>
                                <li><a target="_blank" href="https://discord.com/invite/9UPM6S4xyA">Support Server</a></li> */}

                                <Popover className="relative">
                                    <Popover.Button className="hover:text-red inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
                                        <li><a className='flex flex-row !pr-1'>Support </a> <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /></li>

                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className=" absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-1/2 px-4">
                                            <div className="w-56 shrink rounded-xl bg-darkGray p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                                {support.map((item) => (
                                                    <a key={item.name} href={item.href} target="_blank" className="block p-2 hover:text-indigo-600">
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </Popover>
                                <li className="mr-2 ml-5">
                                    <a
                                        href="https://dashboard.botghost.com/dashboard"
                                        className="btn btn-red sign-up-button"
                                        style={{ padding: "5px 25px", fontWeight: "700" }}
                                    >
                                        Create a bot
                                    </a>
                                </li>

                            </ul>
                        </nav>

                        <nav className="dash-header-menu home-menu mobile-only">
                            <ul>
                                <li className="mr-2">
                                    <a
                                        href="https://dashboard.botghost.com/dashboard"
                                        className="btn btn-red sign-up-button"
                                        style={{ padding: "5px 25px", fontWeight: "700" }}
                                    >
                                        Create a bot
                                    </a>
                                </li>
                                <li className="mobile-only"><span className="home-dropdown-button" onClick={(e) => { this.showMenu(); }}><FontAwesomeIcon icon={faBars} size="2x" color="white" /></span></li>

                            </ul>
                        </nav>
                    </div>

                </header>
                {this.state.menuOpen ?
                    <div className="mobile-nav-dropdown" style={{ backgroundColor: "#222527" }}>
                        <nav>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/features">Features</Link></li>
                                <li><Link to="/tools">Free Tools</Link></li>
                                <li><a href="https://botghost.com/community/" target="_blank" rel="noreferrer">Blog</a></li>
                                <li><Link to="/tutorials">Tutorials</Link></li>
                                <li><a href="https://twitter.com/GetBotGhost">Updates</a></li>
                                <li><a href="https://docs.botghost.com">Documentation</a></li>
                            </ul>
                        </nav>
                    </div> : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default NewHomeNav;
