import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Nav } from './elements/Nav';
import ReactGA from "react-ga";
import { Helmet } from 'react-helmet';

import ModuleItem from "./elements/ModuleItem";
import SectionHeader from "./elements/SectionHeader";

import server from "../api/server";
import NewHomeNav from './elements/NewHomeNav';



export class Affiliate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            link: "",
            contact_info: "",
            partnership_message: "",
            disabled: false,
            submitted: false,
            error: false,
            size: 0,
            monthlyVisitors: 1000,
        };
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    handleVisitorChange = (event) => {
        const visitors = parseInt(event.target.value);
        this.setState({ monthlyVisitors: visitors });
    };

    render() {
        return (
            <html>
                <Helmet>
                    <title>Discord Bot Affiliate Program - BotGhost Affiliate Program</title>
                    <meta name="description" content="Join BotGhost's affiliate program and earn 30% commission promoting the leading Discord bot creator. Perfect for Discord server owners, YouTubers, and content creators." />
                </Helmet>
                <body>
                    <div>
                        <NewHomeNav />

                        <main className="partner-program-container min-h-screen py-8 px-4 sm:px-6 lg:px-8 bg-[#1E2124]">
                            <div className="max-w-7xl mx-auto pt-16">
                                {/* Logo */}
                                <div className="flex justify-center mb-6">
                                    <img src={`${process.env.PUBLIC_URL}/img/logo-red.png`} alt="BotGhost Logo" className="h-12" />
                                </div>

                                {/* Affiliate Header Section */}
                                <div className="text-center mb-8">
                                    <div className="uppercase text-sm font-semibold text-[#9B9D9F] tracking-wider mb-3">
                                        AFFILIATE PROGRAM
                                    </div>
                                    <h1 className="text-4xl md:text-5xl font-bold text-white mb-4 font-rajdhani">
                                        We build the bots. You take 30%
                                    </h1>
                                    <p className="text-lg text-[#9B9D9F] max-w-3xl mx-auto mb-6">
                                        Turn your Discord community or YouTube channel into a revenue stream. Earn 30% commission
                                        by promoting the easiest way to create custom Discord bots.
                                    </p>

                                    {/* Feature List */}
                                    <div className="flex flex-wrap justify-center gap-6 mb-8">
                                        <div className="flex items-center text-[#9B9D9F]">
                                            <svg className="w-5 h-5 text-[#FF4654] mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                            <span>30% commission on all sales</span>
                                        </div>
                                        <div className="flex items-center text-[#9B9D9F]">
                                            <svg className="w-5 h-5 text-[#FF4654] mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                            <span>Monthly payouts</span>
                                        </div>
                                        <div className="flex items-center text-[#9B9D9F]">
                                            <svg className="w-5 h-5 text-[#FF4654] mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                            <span>60-day cookie period</span>
                                        </div>
                                    </div>

                                    {/* CTA Button */}
                                    <a
                                        href="https://botghost.firstpromoter.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-[#FF4654] hover:bg-[#FF5964] text-white font-bold py-4 px-8 rounded-lg transition duration-300 text-lg"
                                    >
                                        Join the BotGhost affiliate program
                                    </a>
                                </div>

                                {/* Earnings Calculator Section */}
                                <div className="mt-16 text-center">
                                    <h2 className="text-2xl font-bold text-white mb-8">Calculate Your Potential Earnings</h2>

                                    {/* Calculator content remains the same */}
                                    <div className="max-w-2xl mx-auto bg-[#2A2C30] rounded-lg p-6">
                                        <h3 className="text-2xl font-bold text-white mb-4">Earnings Calculator</h3>

                                        {/* Visitor Slider */}
                                        <div className="mb-8">
                                            <label className="text-[#9B9D9F] block mb-2">Monthly Visitors</label>
                                            <input
                                                type="range"
                                                min="100"
                                                max="250000"
                                                step="100"
                                                value={this.state.monthlyVisitors}
                                                onChange={this.handleVisitorChange}
                                                className="w-full h-2 bg-[#1E2124] rounded-lg appearance-none cursor-pointer accent-[#FF4654]"
                                            />
                                            <div className="text-white font-bold mt-2">{this.state.monthlyVisitors.toLocaleString()} visitors</div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center mb-6">
                                            <div className="bg-[#1E2124] p-4 rounded-lg">
                                                <p className="text-[#9B9D9F] mb-2">Monthly Visitors</p>
                                                <p className="text-2xl font-bold text-white">{this.state.monthlyVisitors.toLocaleString()}</p>
                                            </div>
                                            <div className="bg-[#1E2124] p-4 rounded-lg">
                                                <p className="text-[#9B9D9F] mb-2">Conversion Rate</p>
                                                <p className="text-2xl font-bold text-white">2.5%</p>
                                            </div>
                                            <div className="bg-[#1E2124] p-4 rounded-lg">
                                                <p className="text-[#9B9D9F] mb-2">Avg. Order Value</p>
                                                <p className="text-2xl font-bold text-white">$30</p>
                                            </div>
                                        </div>
                                        <div className="bg-[#1E2124] p-6 rounded-lg">
                                            <p className="text-[#9B9D9F] mb-2">Your Estimated Monthly Earnings</p>
                                            <p className="text-4xl font-bold text-[#FF4654]">
                                                ${((this.state.monthlyVisitors * 0.025 * 30 * 0.30)).toLocaleString()}
                                            </p>
                                            <p className="text-sm text-[#9B9D9F] mt-2">
                                                {this.state.monthlyVisitors.toLocaleString()} visitors × 2.5% conversion rate = {(this.state.monthlyVisitors * 0.025).toLocaleString()} customers<br />
                                                {(this.state.monthlyVisitors * 0.025).toLocaleString()} customers × $30 × 30% commission = ${((this.state.monthlyVisitors * 0.025 * 30 * 0.30)).toLocaleString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Product Stats Section */}
                                <div className="max-w-7xl mx-auto mt-24">
                                    <div className="grid md:grid-cols-2 gap-12">
                                        <div>
                                            <h2 className="text-4xl font-bold text-white mb-6">
                                                Trusted by over 2 million Discord users
                                            </h2>
                                            <p className="text-[#9B9D9F] mb-8">
                                                BotGhost has powered the creation of over 2 million Discord bots, helping server owners automate and enhance their communities.
                                            </p>
                                            <ul className="space-y-4">
                                                <li className="flex items-start text-[#9B9D9F]">
                                                    <svg className="w-6 h-6 text-[#FF4654] mr-3 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                    </svg>
                                                    Get a sale for every 40 visitors with our 2.5% conversion rate
                                                </li>
                                                <li className="flex items-start text-[#9B9D9F]">
                                                    <svg className="w-6 h-6 text-[#FF4654] mr-3 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                    </svg>
                                                    Every purchase is covered by our 7-day money-back guarantee
                                                </li>
                                                <li className="flex items-start text-[#9B9D9F]">
                                                    <svg className="w-6 h-6 text-[#FF4654] mr-3 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                    </svg>
                                                    Perfect for Discord server owners, YouTubers, and bot enthusiasts
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="grid grid-cols-1 gap-6">
                                            <div className="bg-[#2A2C30] p-8 rounded-lg border border-[#3A3C40]">
                                                <div className="flex items-center mb-4">
                                                    <div className="text-[#9B9D9F]">From a Discord Server Owner</div>
                                                </div>
                                                <p className="text-[#9B9D9F] italic text-lg">
                                                    "BotGhost made creating my server's custom bot so easy. My members love it!"
                                                </p>
                                            </div>
                                            <div className="bg-[#2A2C30] p-8 rounded-lg border border-[#3A3C40]">
                                                <div className="flex items-center mb-4">
                                                    <div className="text-[#9B9D9F]">From a Content Creator</div>
                                                </div>
                                                <p className="text-[#9B9D9F] italic text-lg">
                                                    "I've tried other bot makers, but nothing comes close to BotGhost's simplicity."
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Industry Leading Terms Section */}
                                <div className="max-w-7xl mx-auto mt-24">
                                    <div className="grid md:grid-cols-2 gap-12 items-center">
                                        <div className="bg-[#2A2C30] p-8 rounded-lg border border-[#3A3C40]">
                                            <h3 className="text-2xl font-bold text-white mb-6">Platform Stats</h3>
                                            <div className="grid grid-cols-2 gap-6">
                                                <div className="text-center p-4 bg-[#1E2124] rounded-lg">
                                                    <div className="text-3xl font-bold text-white mb-2">2M+</div>
                                                    <div className="text-[#9B9D9F]">Bots Created</div>
                                                </div>
                                                <div className="text-center p-4 bg-[#1E2124] rounded-lg">
                                                    <div className="text-3xl font-bold text-white mb-2">1.5M+</div>
                                                    <div className="text-[#9B9D9F]">Happy Users</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h2 className="text-4xl font-bold text-white mb-6">Industry-leading affiliate terms</h2>
                                            <p className="text-[#9B9D9F] mb-8">
                                                We take care of our affiliates by offering the highest commission rates in the Discord bot industry.
                                            </p>
                                            <ul className="space-y-6">
                                                <li className="text-[#9B9D9F]">
                                                    <h3 className="text-white font-bold mb-2">High commission rate at 30%</h3>
                                                </li>
                                                <li className="text-[#9B9D9F]">
                                                    <h3 className="text-white font-bold mb-2">Minimum payout of $50</h3>
                                                </li>
                                                <li className="text-[#9B9D9F]">
                                                    <h3 className="text-white font-bold mb-2">Monthly payouts via PayPal</h3>
                                                </li>
                                                <li className="text-[#9B9D9F]">
                                                    <h3 className="text-white font-bold mb-2">60-day cookie duration</h3>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* How it Works Section */}
                                <div className="max-w-7xl mx-auto mt-24">
                                    <div className="text-center mb-12">
                                        <h2 className="text-4xl font-bold text-white mb-6">How it works</h2>
                                        <p className="text-[#9B9D9F] text-lg">Simple steps to start earning with BotGhost</p>
                                    </div>

                                    <div className="grid md:grid-cols-3 gap-8">
                                        <div className="bg-[#2A2C30] p-8 rounded-lg relative">
                                            <div className="absolute -top-4 left-8 bg-[#FF4654] text-white w-8 h-8 rounded-full flex items-center justify-center font-bold">
                                                1
                                            </div>
                                            <h3 className="text-white font-bold text-xl mb-4 mt-2">Sign Up</h3>
                                            <p className="text-[#9B9D9F]">
                                                Sign up to the affiliate program through the form below. Once submitted, we'll review your application.
                                            </p>
                                        </div>

                                        <div className="bg-[#2A2C30] p-8 rounded-lg relative">
                                            <div className="absolute -top-4 left-8 bg-[#FF4654] text-white w-8 h-8 rounded-full flex items-center justify-center font-bold">
                                                2
                                            </div>
                                            <h3 className="text-white font-bold text-xl mb-4 mt-2">Get Your Link</h3>
                                            <p className="text-[#9B9D9F]">
                                                If accepted, you'll receive your unique affiliate link to promote BotGhost across your platform and content.
                                            </p>
                                        </div>

                                        <div className="bg-[#2A2C30] p-8 rounded-lg relative">
                                            <div className="absolute -top-4 left-8 bg-[#FF4654] text-white w-8 h-8 rounded-full flex items-center justify-center font-bold">
                                                3
                                            </div>
                                            <h3 className="text-white font-bold text-xl mb-4 mt-2">Earn Commission</h3>
                                            <p className="text-[#9B9D9F]">
                                                Earn 30% commission on all referred users' first payments, including lifetime/unlimited subscriptions.
                                            </p>
                                        </div>
                                    </div>

                                    {/* Additional Important Information */}
                                    <div className="mt-12 bg-[#2A2C30] p-8 rounded-lg">
                                        <h3 className="text-white font-bold text-xl mb-4">Important Details</h3>
                                        <ul className="space-y-4">
                                            <li className="flex items-start text-[#9B9D9F]">
                                                <svg className="w-6 h-6 text-[#FF4654] mr-3 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                </svg>
                                                <span>Get paid via PayPal every 30 days on balances above $50 USD</span>
                                            </li>
                                            <li className="flex items-start text-[#9B9D9F]">
                                                <svg className="w-6 h-6 text-[#FF4654] mr-3 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                </svg>
                                                <span>60-day cookie tracking ensures you get credit for referred users</span>
                                            </li>
                                            <li className="flex items-start text-[#9B9D9F]">
                                                <svg className="w-6 h-6 text-[#FF4654] mr-3 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                                </svg>
                                                <span className="text-[#FF4654]">Note: Advertising your affiliate link in any BotGhost owned content or platform (including the Discord Server) will result in the immediate suspension of your affiliate account.</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Final CTA Section */}
                                <div className="max-w-3xl mx-auto mt-24 text-center pb-16">
                                    <h2 className="text-4xl font-bold text-white mb-6">Ready to start earning?</h2>
                                    <p className="text-lg text-[#9B9D9F] mb-8">
                                        Start earning by promoting the easiest way to create Discord bots
                                    </p>
                                    <a
                                        href="https://botghost.firstpromoter.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center justify-center bg-[#FF4654] hover:bg-[#FF5964] text-white font-bold py-4 px-8 rounded-lg transition duration-300 text-lg group"
                                    >
                                        Start earning now
                                        <svg
                                            className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 7l5 5m0 0l-5 5m5-5H6"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </main>




                    </div>
                    <script src="https://js.stripe.com/v3/"></script>
                    <script type="text/javascript" src="https://unpkg.com/popper.js@1.15.0/dist/umd/popper.min.js"></script>
                    <script src="https://code.jquery.com/jquery-3.5.1.min.js" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
                    <script src="/js/bootstrap.min.js"></script>
                    <script src="/js/bootstrap-colorpicker.min.js"></script>
                    <script type="text/javascript" src="/js/angular.min.js"></script>
                    <script src="/dashboard/node_modules/toast-master/js/jquery.toast.js"></script>
                    <script type="text/javascript" src="/js/dash.js"></script>
                    <script data-ad-client="ca-pub-6273221994052603" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

                    <script async defer src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
                    <noscript><img src="https://queue.simpleanalyticscdn.com/noscript.gif" alt="" /></noscript>

                    <style>
                        {`
                        /* Webkit browsers custom slider */
                        input[type='range']::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            appearance: none;
                            width: 20px;
                            height: 20px;
                            background: #FF4654;
                            border-radius: 50%;
                            cursor: pointer;
                            transition: background .15s ease-in-out;
                        }
                        input[type='range']::-webkit-slider-thumb:hover {
                            background: #FF5964;
                        }

                        /* Firefox custom slider */
                        input[type='range']::-moz-range-thumb {
                            width: 20px;
                            height: 20px;
                            background: #FF4654;
                            border: none;
                            border-radius: 50%;
                            cursor: pointer;
                            transition: background .15s ease-in-out;
                        }
                        input[type='range']::-moz-range-thumb:hover {
                            background: #FF5964;
                        }
                    `}
                    </style>
                </body>
            </html>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default Affiliate;
