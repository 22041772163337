import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import NewHomeNav from '../elements/NewHomeNav';
import SeoHeader from '../seo_elements/SeoHeader';
import FeatureSection from '../seo_elements/FeatureSection';
import SecondaryFeatures from '../seo_elements/SecondaryFeatures';
import Testimonial from '../seo_elements/Testimonial';
import CTABanner from '../seo_elements/CTABanner';
import FAQ from '../seo_elements/FAQ';
import TestimonialCollage from '../seo_elements/TestimonialCollage';
import StepByStep from '../seo_elements/StepByStep';
import YouTubeSection from '../seo_elements/YouTubeSection';
import MainNav from '../elements/MainNav';
import ModulesSection from '../seo_elements/ModulesSection';
import RedCTA from '../seo_elements/RedCTA';
import HomeCommandBuilder from '../elements/HomeCommandBuilder';
import TutorialsSection from '../seo_elements/TutorialsSection';
import SEOBuilderDemo from '../seo_elements/SEOBuilderDemo';
import DetailedTutorials from '../seo_elements/DetailedTutorials';

export class ActiveDevBadge extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        const testimonials = [
            {
                title: "Host your own custom Discord bot",
                subtitle: "Create a custom Discord bot with no coding required",
                quote: "BotGhost makes your dream of owning a custom bot a reality with the easiest and most user-friendly experience!",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ninjapfp.webp",
                name: "NinjaChato",
                role: "Custom bot owner"
            },
            {
                quote: "BotGhost revolutionized our Discord with automation and interactive features, making our server efficient and engaging!",
                title: "Automate your Discord server",
                subtitle: 'Automate your Discord server with our custom built modules',
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/static-1-.png",
                name: "Silas",
                role: "Server Owner"
            },
            {
                quote: "BotGhost lets Rolace Legacy create quick, complex projects with no scripting, using powerful tools for seamless customization",
                title: "Create complex Discord bots",
                subtitle: "Host and create your own complex Discord bots",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/AstorRolace.png",
                name: "Astor Rolace",
                role: "Bot Creator"
            },
            {
                quote: "As a public bot owner BotGhost has given me the ability to create anything my brain can think of!",
                title: "Design your own public Discord bots",
                subtitle: "Become a professional bot designer",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Untitled294_20240719135012.png",
                name: "Oni",
                role: "Bot Designer"
            },
            {
                quote: "Easy to use, reliable, and looks great. I get so many compliments on my bots.",
                title: "Reliable Discord bot hosting",
                subtitle: "Host your own Discord bot with 99.9% uptime",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Fq2LXwKagAEpknu.jpg",
                name: "clowdy",
                role: "Discord Bot Owner"
            },
            {
                quote: "BotGhost is amazing! Its features and ease of use made me upgrade from monthly to a lifetime subscription without hesitation!",
                title: "Easy to use Discord Bot Maker",
                subtitle: "Create your own Discord bot with ease",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/discotools-xyz-icon.png",
                name: "Nathan",
                role: "Server Admin"
            }
            // ... more testimonials
        ];
        const mainFeatures = [
            {
                title: "Hassle free bot hosting",
                description: "Keep your Discord bot running smoothly with our enterprise-grade hosting infrastructure supplied by AWS. Get detailed error logs, automatic restarts, crash management and instant notifications if any issues arise.",
                bulletPoints: [
                    "24/7 uptime monitoring",
                    "Automatic crash recovery"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/HassleFreeHostBG.png"
            },
            {
                title: "Drag and Drop Builder",
                description: "Utilize the first drag and drop Discord bot builder. Create your own commands and events with ease. Absolutely no coding required.",
                bulletPoints: [
                    "No coding required",
                    "Create your own commands and events"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
            },
            {
                title: "One-Click Deployment",
                description: "Deploy your Discord bot instantly with our streamlined hosting platform. No complex setup required - just connect your bot and we handle the rest.",
                bulletPoints: [
                    "Automated deployment process",
                    "Instant configuration updates"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/OneClickDeployBG.png"
            },
            {
                title: "Advanced Security",
                description: "Keep your bot and data secure with enterprise-grade security features. Benefit from automated backups, encryption, IP rotation, DDOS protection and more.",
                bulletPoints: [
                    "24/7 DDoS protection",
                    "Automated daily backups"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SecurityBG.png"
            },
            {
                title: "Scalable Infrastructure",
                description: "Grow your bot without limitations. Our infrastructure scales automatically as your bot's needs increase, ensuring smooth performance at any size.",
                bulletPoints: [
                    "Priority hosting",
                    "Scalable resources"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ScalableInfastructureBG.png"
            },
            {
                title: "Database Management",
                description: "Manage your bot's data with ease. Our platform includes a built-in database management system that allows you to store and retrieve data for your bot's commands and events.",
                bulletPoints: [
                    "Built-in database management",
                    "Store and retrieve data for your bot's commands and events"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/DatabaseBG.png"
            }
        ];

        const secondaryFeatures = [
            {
                title: "Active Developer Badge",
                description: "Get the exclusive Discord Active Developer Badge in under 5 minutes. No coding required - just enable our module and run a command.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/active_dev.png"
            },
            {
                title: "One-Click Setup",
                description: "Enable the Active Developer Badge module with a single click. We handle all the technical requirements for you.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
            },
            {
                title: "Instant Verification",
                description: "Our system automatically verifies your slash command usage and prepares your bot for badge eligibility.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/247-hosting.png"
            },
            {
                title: "Step-by-Step Guide",
                description: "Follow our simple guide to get your badge. We'll walk you through every step of the process.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/advanced-dashboard.png"
            },
            {
                title: "Community Support",
                description: "Join our active Discord community for help with setting up your bot and claiming your badge. Our support team is available 24/7.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/custommodules.png"
            },
            {
                title: "Free Forever",
                description: "Get your Active Developer Badge completely free. No hidden fees, no credit card required - just create your bot and claim your badge.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/botpanelintegration.png"
            }
        ];



        const tutorialSteps = [
            {
                title: "Create your Discord Bot",
                description: "Head to the Discord Developer Portal and click 'New Application'. Name your bot, accept the Terms of Service and Developer Policy, then click Create.",
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/create-bot.png"
            },
            {
                title: "Get your Bot Token",
                description: "Click 'Bot' in the left menu, then 'Add Bot'. Click 'Reset Token' to get your bot's token - keep this safe and don't share it with anyone!",
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/bot-token.png"
            },
            {
                title: "Link to BotGhost",
                description: "Head to the BotGhost dashboard and follow the instructions to link your bot to BotGhost. Paste in your bot's token and click 'Create Bot'.",
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/add-token.png"
            },
            {
                title: "Enable the Active Developer Module",
                description: <>
                    <p>
                        In the BotGhost dashboard, find and enable the <a href="https://dashboard.botghost.com/dashboard/module/be383fbe-8339-433b-a51c-94db234fde38/activedeveloperbadge" target="_blank" className="text-[#f45142]">Active Developer Badge</a> module. Make sure the command toggle is enabled and save changes.
                    </p>
                    <p>
                        You can also enable the module by clicking 'Modules' in the left menu, then 'Active Developer Badge'.
                    </p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/slash-command.png"
            },
            {
                title: "Test your Command",
                description: "Click 'Invite' in the left menu to add your bot to a server. Use the /activedevbadge command in any channel - you should get a success message.",
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/test-command.png"
            },
            {
                title: "Get your Badge",
                description: <>
                    <p>
                        Wait 24 hours, then visit the <a href="https://discord.com/developers/active-developer" target="_blank" className="text-[#f45142]">Active Developer Badge</a> page. Select your bot, set up a community server as support server, and claim your badge!
                    </p>
                </>,
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/active-badge.png"
            }
        ];

        const faqQuestions = [
            {
                question: "What is the Active Developer Badge?",
                answer: "The Active Developer Badge is a special badge that Discord gives to developers who create and maintain active Discord bots. It appears on your Discord profile and shows that you're an active bot developer in the Discord community."
            },
            {
                question: "How long does it take to get the Active Developer Badge?",
                answer: "After running your first slash command, you need to wait 24 hours before you can claim the Active Developer Badge. Once you've waited 24 hours, you can visit the Active Developer Portal to claim your badge."
            },
            {
                question: "How do I claim the Active Developer Badge?",
                answer: "After waiting 24 hours from your first slash command, visit the Discord Active Developer Portal, select your bot, set up a community server as your support server, and click the claim button. The badge will appear on your profile within a few minutes."
            },
            {
                question: "Do I need to code to get the Active Developer Badge?",
                answer: "No! With BotGhost, you can get the Active Developer Badge without any coding. Simply create your bot, enable our Active Developer Badge module, and run the provided slash command - we handle all the technical details for you."
            },
            {
                question: "Can I get the Active Developer Badge on mobile?",
                answer: "Yes, while you'll need to use a desktop or web browser to set up your bot initially, you can run the required slash command from the Discord mobile app. The badge will show on your profile across all platforms."
            },
            {
                question: "What if I don't have a community server?",
                answer: "You can create a community server by clicking 'Create a Community Server' in the Active Developer Portal. This will allow you to claim your badge and show it off to your community."
            },
            {
                question: "Is it free?",
                answer: "Yes! BotGhost is free to use and you can get the Active Developer Badge for free."
            }
        ];

        return (
            <div>
                <Helmet>
                    <title>Active Developer Badge | BotGhost</title>
                    <meta name="description" content="Get the Active Developer Badge in under 5 minutes by creating your bot on BotGhost. No coding required - just click, customize, and launch your bot in minutes." />
                </Helmet>

                <NewHomeNav version="v2" style={"no-border"} />
                {/* <MainNav /> */}
                <SeoHeader
                    title={
                        <>
                            Get the <span className="text-[#f45142]">Active Developer Badge</span> in under 5 minutes
                        </>
                    }
                    description="Get the Active Developer Badge in under 5 minutes by creating your bot on BotGhost. No coding required - just click, customize, and launch your bot in minutes."
                    cta="Get the badge"
                    ctaSecondary="How it works"
                    img="https://botghost-cdn.s3.us-east-2.amazonaws.com/Discord_Active_Developer_Badge.svg.png"
                    ctaSecondaryLink="#tutorial"
                    stats={true}
                />
                <DetailedTutorials
                    title={<>How to get the <span className="text-[#f45142]">Active Developer Badge</span></>}
                    subtitle="Follow these simple steps to get your Active Developer Badge in under 5 minutes"
                    steps={tutorialSteps}
                    ctaText="Get Started Now"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />

                <FAQ questions={faqQuestions} />

                <TutorialsSection />

                <SecondaryFeatures
                    title={
                        <>
                            Everything you need to <span className="text-[#f45142]">get the Active Developer Badge</span>. No coding required.
                        </>
                    }
                    description="Get the Active Developer Badge in under 5 minutes by hosting your bot on BotGhost. No coding required - just click, customize, and launch your bot in minutes."
                    features={secondaryFeatures}
                />

                <YouTubeSection
                    title="Watch how to get the Active Developer Badge"
                    description="Learn how to get the Active Developer Badge in under 5 minutes"

                    videoId="1aBMOGSdq3E"
                />

                <CTABanner
                    title={<>Ready to get your <span className="text-[#f45142]">Active Developer Badge?</span></>}
                    subtitle="Get started with BotGhost and claim your Active Developer Badge in minutes"
                    ctaText="Get Started Now"
                    img="https://botghost-cdn.s3.us-east-2.amazonaws.com/Discord_Active_Developer_Badge.svg.png"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />





                {/* <div className="hidden md:block">
                    <SEOBuilderDemo />
                </div> */}

                {/* <FeatureSection
                    id="features"
                    title={
                        <>
                            Powerful Discord Bot Hosting with <span className="text-[#f45142]">endless features</span>

                        </>
                    }
                    description="Discover our comprehensive suite of Discord bot hosting features designed to give you complete control. Create your own commands and events with our drag and drop builder."

                    features={mainFeatures}
                /> */}

                {/* <HomeCommandBuilder /> */}

                {/* <Testimonial
                    quote="BotGhost provides me with a convenient way to build custom bots for myself and various content creators I work for."
                    author="Discord Server Admin"
                    role="Admin for multiple Discord servers"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/SchnifL_pfp_r2.png"
                />


                <TestimonialCollage
                    testimonials={testimonials}
                />




                <ModulesSection />
                <RedCTA
                    title="Ready to power up your Discord server?"
                    subtitle="Join thousands of server owners who trust BotGhost for reliable, feature-rich Discord bot hosting."
                    buttonText="Start hosting for free"
                />
                <TutorialsSection />
                <YouTubeSection
                    title="Watch How It Works"
                    description="Learn how to start hosting your Discord bot on BotGhost"
                />

                <StepByStep
                    title="Simple steps to start hosting your Discord bot on BotGhost"
                    description="Get started with professional Discord bot hosting in minutes"
                />

                <CTABanner
                    title="Start building your Discord bot"
                    highlight="today"
                    subtitle="Get started with professional Discord bot hosting in minutes"
                    ctaText="Start hosting for free"
                    ctaLink="/signup"
                /> */}


            </div>
        );
    }
}

export default ActiveDevBadge;
