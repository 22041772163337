import React, { Component } from 'react';

export class CTABanner extends Component {
    render() {
        const { title, highlight, subtitle, ctaText, ctaLink, img } = this.props;

        return (
            <div className="bg-[#1E2124] py-32">
                <div className="max-w-7xl mx-auto px-4 text-center">
                    <div className="flex justify-center mb-4">
                        <img
                            src={img ? img : `${process.env.PUBLIC_URL}/img/logo-red.png`}
                            alt="Ghost"
                            className="w-16 h-16 animate-bounce"
                        />
                    </div>



                    <h2 className="text-5xl font-bold text-white mb-6">
                        {title}
                    </h2>

                    <p className="text-[#9B9D9F] text-xl mb-8">
                        {subtitle}
                    </p>

                    <div className="flex flex-col items-center gap-4">
                        <a
                            href={ctaLink}
                            className="bg-[#f45142] text-white px-12 py-4 rounded-full text-lg font-semibold hover:bg-[#ff6152] transition-colors duration-200"
                        >
                            {ctaText}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default CTABanner; 