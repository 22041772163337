import React, { Component } from 'react';



class SectionHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <div>
                <p class="section-pretitle">{this.props.pretitle} </p>
                <h2 class="section-title">{this.props.title}</h2>
            </div>
         );
    }
}
 
export default SectionHeader;