import React, { Component } from 'react';

export class SeoHeader extends Component {
    render() {
        const { title, subtitle, description, cta, ctaLink, ctaSecondary, ctaSecondaryLink, stats } = this.props;

        return (
            <div className="bg-[#1E2124] pt-32 pb-32">
                <div className="text-center max-w-7xl mx-auto px-4">
                    <div className="flex justify-center mb-8">
                        <img
                            src={this.props.img ? this.props.img : `${process.env.PUBLIC_URL}/img/logo-red.png`}
                            alt="BotGhost"
                            className="w-20 h-20 animate-bounce"
                        />
                    </div>
                    <h1 className="text-6xl md:text-7xl font-bold text-[#ffffff] mb-8 font-sans leading-tight">
                        {this.props.title}
                    </h1>
                    <p className="text-xl text-[#ffffff] opacity-90 max-w-3xl mx-auto mb-12">
                        {this.props.description}
                    </p>
                    <div className="flex flex-wrap justify-center gap-6 mb-24">
                        {cta && (
                            <a
                                href={"https://dashboard.botghost.com/dashboard"}
                                className="bg-[#f45142] text-white hover:bg-[#ff6152] font-semibold py-3 px-10 rounded-full text-lg transition-colors duration-200"
                            >
                                {cta}
                            </a>
                        )}
                        {ctaSecondary && (
                            <a
                                href={ctaSecondaryLink}
                                className="text-white font-semibold py-3 px-8 transition-colors duration-200 text-lg flex items-center hover:text-white/80"
                            >
                                {ctaSecondary} <span className="ml-2">›</span>
                            </a>
                        )}
                    </div>

                    {stats && (
                        <div>
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-12 max-w-5xl mx-auto">
                                <div className="text-center">
                                    <div className="text-4xl font-bold text-[#f45142] mb-3">2M+</div>
                                    <div className="text-[#9B9D9F]">Bots Created</div>
                                </div>
                                <div className="text-center">
                                    <div className="text-4xl font-bold text-[#f45142] mb-3">1.5M+</div>
                                    <div className="text-[#9B9D9F]">Happy Users</div>
                                </div>
                                <div className="text-center">
                                    <div className="text-4xl font-bold text-[#f45142] mb-3">100M+</div>
                                    <div className="text-[#9B9D9F]">Interactions Created</div>
                                </div>
                                <div className="text-center">
                                    <div className="text-4xl font-bold text-[#f45142] mb-3">10M+</div>
                                    <div className="text-[#9B9D9F]">Commands Created</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default SeoHeader; 