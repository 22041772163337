import React, { Component } from 'react';

export class RedCTA extends Component {
    render() {
        const {
            title = "Start building your Discord bot today",
            subtitle = "Get started with professional Discord bot hosting in minutes. No credit card required.",
            buttonText = "Start hosting for free",
            buttonLink = "/signup"
        } = this.props;

        return (
            <div className="bg-[#f45142] py-24">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center">
                        <h2 className="text-4xl md:text-5xl font-bold text-white mb-4 leading-tight">
                            {title}
                        </h2>
                        <p className="text-white text-xl max-w-3xl mx-auto mt-6 mb-10">
                            {subtitle}
                        </p>
                        <a
                            href={"https://dashboard.botghost.com/dashboard"}
                            className="inline-block border-2 border-white text-white px-10 py-4 rounded-full font-semibold text-lg transition-colors duration-200 hover:bg-white hover:text-[#f45142]"
                        >
                            {buttonText}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default RedCTA;