import React, { Component } from 'react';
import {connect} from 'react-redux'


// import {updatePage} from "../../../actions"

// import history from "../../../history"

import history from "../../history"

class ModuleItem extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    setPage = (page) =>{
        
        var slug = page.toLowerCase();
        slug = slug.split(" ").join("")
        history.push("/dashboard/"+slug)

        // this.props.updatePage(slug)
    }
    render() { 
        return (
            <div onClick={(e) =>{this.setPage(this.props.title)}} className={`module-item ${this.props.premium && this.props.bot.premium ==false  ? "module-premium":""}`}>
                {this.props.premium && this.props.bot.premium ==false ? <span className="module-badge">Premium</span> : null}
                
                <img src={process.env.PUBLIC_URL + `/images/icons/${this.props.icon}`}></img>
                <h3>{this.props.title}</h3>
                <p>{this.props.description}</p>
            </div>
         );
    }
}

const mapStateToProps = (state) =>({
    bot:state.data.bot,
    premium:state.data.premium
})
 
export default ModuleItem