import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import server from '../../api/server';

export class ModulesSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modules: [],
            loaded: false
        };
    }
    async componentDidMount() {
        var response = await server.get("/home/modules");
        console.log(response.data, "MODULES");
        if (response && response.data) {
            this.setState({ modules: response.data, loaded: true });
        }
    }

    render() {
        // Dummy data - will be replaced with API data
        const modules = this.state.modules;

        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            speed: 5000,
            autoplaySpeed: 0,
            cssEase: "linear",
            pauseOnHover: true,
            pauseOnFocus: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };



        return (
            <div className="bg-[#1E2124] py-24 overflow-hidden">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl md:text-5xl font-bold text-white mb-4 leading-tight">
                            Handcrafted <span className="text-[#f45142]">Modules</span> ready to go
                        </h2>
                        <p className="text-[#9B9D9F] text-xl max-w-3xl mx-auto mt-6">
                            Get started with 30+ fully customizable built-in modules. Edit them to fit your needs.
                        </p>
                    </div>

                    <div className="px-4 py-8">
                        <Slider {...settings}>
                            {modules.map((module, index) => (
                                <div key={index} className="px-3 py-4">
                                    <div className="bg-[#242830] rounded-xl p-8 hover:bg-[#2A2F38] transition-all duration-300 
                                                  transform hover:-translate-y-1 hover:shadow-2xl cursor-pointer 
                                                  border border-transparent hover:border-gray-700 h-[300px] 
                                                  flex flex-col items-center">
                                        <div className="mb-6">
                                            <div className="bg-[#f45142] rounded-full p-3 w-16 h-16
                                                          flex items-center justify-center">
                                                <img
                                                    src={module.img}
                                                    alt={module.name}
                                                    className="w-10 h-10 object-contain"
                                                />
                                            </div>
                                        </div>
                                        <h4 className="text-xl font-bold text-white mb-3 text-center">
                                            {module.name}
                                        </h4>
                                        <p className="text-[#9B9D9F] text-sm leading-relaxed text-center line-clamp-3">
                                            {module.description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModulesSection; 