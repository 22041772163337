import React, { Component } from 'react';

export class StepByStep extends Component {
    render() {
        const steps = [
            {
                number: "1",
                title: "Sign Up",
                description: "Create your free account. No credit card required to get started."
            },
            {
                number: "2",
                title: "Input your Bot's token",
                description: "Input your Bot's token to start hosting your bot."
            },
            {
                number: "3",
                title: "Invite your Bot",
                description: "Invite your bot to your server. It will start working immediately."
            },
            {
                number: "4",
                title: "Create some commands",
                description: "Create some commands to start interacting with your bot."
            },
            {
                number: "5",
                title: "Edit your modules",
                description: "Edit your modules to fit your needs. Enable or Disable them as you see fit."
            },
            {
                number: "6",
                title: "Go Live",
                description: "Launch your bot to your community. It will be available 24/7."
            }
        ];

        return (
            <div className="bg-[#1E2124] py-24">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl font-bold text-white mb-4">
                            {this.props.title}
                        </h2>
                        <p className="text-[#9B9D9F] text-lg">
                            {this.props.description}
                        </p>
                    </div>

                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-8 max-w-6xl mx-auto">
                        {steps.map((step) => (
                            <div key={step.number} className="bg-[#242830] p-8 rounded-lg relative">
                                <div className="bg-[#f45142] w-8 h-8 rounded-full flex items-center justify-center text-white font-bold absolute -top-4 -left-4">
                                    {step.number}
                                </div>
                                <h3 className="text-xl font-bold text-white mb-3">
                                    {step.title}
                                </h3>
                                <p className="text-[#9B9D9F]">
                                    {step.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default StepByStep; 