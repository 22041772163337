import React, { Component } from 'react';
import { CUSTOM_EVENTS } from './builder_demo/builder_vars';

export class EventOptions extends Component {
    render() {
        const { title = "Automate your Discord server with events", description = "Choose from our wide range of Discord events to automate your server. Set up welcome messages, member tracking, role assignments, and more." } = this.props;

        // Group events by category
        const eventGroups = {
            "Message Events": CUSTOM_EVENTS.filter(event =>
                event.event_category === "message" ||
                event.value.includes('message') ||
                event.value.includes('reaction')
            ),
            "Member Events": CUSTOM_EVENTS.filter(event =>
                event.event_category === "member" ||
                event.value.includes('member') ||
                event.value.includes('user')
            ),
            "Server Events": CUSTOM_EVENTS.filter(event =>
                event.event_category === "guild" ||
                event.value.includes('guild') ||
                event.value.includes('boost')
            ),
            "Voice Events": CUSTOM_EVENTS.filter(event =>
                event.event_category === "voice" ||
                event.value.includes('voice')
            ),
            "Other Events": CUSTOM_EVENTS.filter(event =>
                event.event_category === "other" ||
                event.value.includes('webhook') ||
                event.value.includes('timer')
            )
        };

        return (
            <div className="bg-[#1E2124] py-24">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl md:text-5xl font-bold text-[#f1f1f1] mb-6">
                            {title}
                        </h2>
                        <p className="text-xl text-[#9B9D9F] max-w-3xl mx-auto">
                            {description}
                        </p>
                    </div>

                    {Object.entries(eventGroups).map(([groupName, events]) => events.length > 0 && (
                        <div key={groupName} className="mb-16">
                            <h3 className="text-2xl font-bold text-[#f1f1f1] mb-8">{groupName}</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {events.map((event, index) => (
                                    <div
                                        key={index}
                                        className="bg-[#242830] rounded-lg p-6 transform hover:scale-105 transition-all duration-200 hover:bg-[#2A2D35]"
                                    >
                                        <div className="flex items-start space-x-4">
                                            <div className="flex-shrink-0">
                                                <div className="w-3 h-3 rounded-full bg-[#f45142] mt-2"></div>
                                            </div>
                                            <div>
                                                <h4 className="text-lg font-semibold text-[#f1f1f1] mb-2">
                                                    {event.label}
                                                </h4>
                                                {event.components && (
                                                    <div className="flex flex-wrap gap-2 mt-3">
                                                        {event.components.Guild && (
                                                            <span className="px-2 py-1 text-xs rounded bg-[#f45142]/10 text-[#f45142]">
                                                                Server
                                                            </span>
                                                        )}
                                                        {event.components.User && (
                                                            <span className="px-2 py-1 text-xs rounded bg-[#f45142]/10 text-[#f45142]">
                                                                User
                                                            </span>
                                                        )}
                                                        {event.components.Member && (
                                                            <span className="px-2 py-1 text-xs rounded bg-[#f45142]/10 text-[#f45142]">
                                                                Member
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default EventOptions; 