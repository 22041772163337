
import {
    faRandom,
    faEdit,
    faAd,
    faAnchor,
    faBars,
    faBrain,
    faBullhorn,
    faCartArrowDown,
    faCartPlus,
    faChartLine,
    faCloud,
    faUserCog,
    faCogs,
    faCommentDots,
    faDog,
    faDollarSign,
    faEnvelope,
    faFileInvoiceDollar,
    faGamepad,
    faGift,
    faHammer,
    faHandScissors,
    faHatCowboy,
    faHeart,
    faHeartbeat,
    faHourglassHalf,
    faInfo,
    faLanguage,
    faLaughSquint,
    faLock,
    faLockOpen,
    faMedal,
    faMoneyBill,
    faMoneyBillAlt,
    faMusic,
    faPoll,
    faSearch,
    faShip,
    faSlash,
    faSmile,
    faSurprise,
    faTicketAlt,
    faTrash,
    faUsers,
    faUserSecret,
    faUserTag,
    faWallet,
    faFile,
    faDatabase,
    faEquals,
    faCalculator,
    faTimes,
    faCalendarPlus,
    faIdBadge,
    faMapPin,
    faTable,
    faExclamation,
    faFingerprint,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { faHourglassEnd, faCopy, faFileImport, faCode, faCodeBranch, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faQuestion, faHourglass, faComment, faComments, faCommentSlash, faUserSlash, faUserTimes, faUserFriends, faUsersSlash, faStore } from "@fortawesome/free-solid-svg-icons";

import { isNode, isEdge } from "react-flow-renderer";


export function splitElements(elements) {
    var nodes = [];
    var edges = [];
    elements.forEach(element => {
        if (isNode(element)) {
            nodes.push(element);
        } else if (isEdge(element)) {
            edges.push(element);
        }
    });

    return {
        nodes: nodes,
        edges: edges
    };
}


export const CUSTOM_EVENTS = [
    {
        value: "IFTTTActionEvent",
        label: "When an IFTTT Action is executed",
        event_category: "ifttt",
        show: false,
        server_required: true,
        components: {
            Guild: true,
            User: false,
            Member: false,
            variables: [
                {
                    variable: "{ifttt_variable1}",
                    name: "IFTTT Event Variable 1"
                },
                {
                    variable: "{ifttt_variable2}",
                    name: "IFTTT Event Variable 2"
                },
                {
                    variable: "{ifttt_variable3}",
                    name: "IFTTT Event Variable 3"
                },
                {
                    variable: "{ifttt_variable4}",
                    name: "IFTTT Event Variable 4"
                },
                {
                    variable: "{ifttt_variable5}",
                    name: "IFTTT Event Variable 5"
                },
                {
                    variable: "{ifttt_variable6}",
                    name: "IFTTT Event Variable 6"
                },
                {
                    variable: "{ifttt_variable7}",
                    name: "IFTTT Event Variable 7"
                },
                {
                    variable: "{ifttt_variable8}",
                    name: "IFTTT Event Variable 8"
                },
                {
                    variable: "{ifttt_variable9}",
                    name: "IFTTT Event Variable 9"
                },
                {
                    variable: "{ifttt_variable10}",
                    name: "IFTTT Event Variable 10"
                }
            ]
        }
    },
    {
        value: "webhookExecuted",
        label: "When a webhook is executed",
        event_category: "webhook",
        show: false,
        server_required: true,
        components: {
            Guild: true,
            User: false,
            Member: false,
            variables: []
        }
    },
    {
        value: "timedEvent",
        label: "When a timed event is executed",
        event_category: "timed_event",
        show: false,
        server_required: true,
        components: {
            Guild: true,
            User: false,
            Member: false,
            variables: []
        }
    },
    {
        value: "serverJoin",
        label: "When the bot joins a server",
        event_category: "bot",

        components: {
            Guild: true,
            User: false,
            Member: false,
            variables: []
        }
    },
    {
        value: "botRestart",
        label: "When the bot starts or is restarted",
        event_category: "bot",

        server_required: true,
        components: {
            Guild: true,
            User: false,
            Member: false,
            variables: []
        }
    },
    {
        value: "customVariableChange",
        label: "When a custom variable's value changes",

        event_category: "bot",
        components: {
            Guild: true,
            User: false,
            Member: false,
            variables: [
                {
                    variable: "{event_variable_old_value}",
                    name: "variable old value"
                },
                {
                    variable: "{event_variable_new_value}",
                    name: "variable new value"
                },
                {
                    variable: "{event_variable_name}",
                    name: "variable name"
                },
                {
                    variable: "{event_variable_id}",
                    name: "variable specific id"
                },
                {
                    variable: "{event_variable_reference}",
                    name: "variable reference"
                }
            ]
        }
    },
    {
        value: "inviteCreate",
        label: "When an invite is created",

        event_category: "invite",
        components: {
            Guild: true,
            User: true,
            Channel: true,
            variables: [
                {
                    variable: "{invite_code}",
                    name: "invite code"
                },
                {
                    variable: "{invite_url}",
                    name: "invite url"
                },
                {
                    variable: "{invite_max_age}",
                    name: "invite max age"
                },
                {
                    variable: "{invite_max_uses}",
                    name: "invite max uses"
                },
                {
                    variable: "{invite_created_at}",
                    name: "invite created at"
                },

                {
                    variable: "{invite_uses}",
                    name: "invite uses"
                },
                {
                    variable: "{invite_expires_at}",
                    name: "invite expires at"
                }
            ]
        }
    },
    {
        value: "messagePollVoteAdd",
        label: "When a user votes on a poll",

        event_category: "poll",
        components: {
            Guild: false,
            User: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_poll_answer_id}",
                    name: "poll answer id"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_poll_answer_emoji}",
                    name: "poll answer emoji"
                },
                {
                    variable: "{event_poll_answer_text}",
                    name: "poll answer text"
                },
                {
                    variable: "{event_poll_answer_vote_count}",
                    name: "poll answer vote count"
                }
            ]
        }
    },
    {
        value: "messagePollVoteRemove",
        label: "When a user removes their vote on a poll",

        event_category: "poll",
        components: {
            Guild: false,
            User: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_poll_answer_id}",
                    name: "poll answer id"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_poll_answer_emoji}",
                    name: "poll answer emoji"
                },
                {
                    variable: "{event_poll_answer_text}",
                    name: "poll answer text"
                },
                {
                    variable: "{event_poll_answer_vote_count}",
                    name: "poll answer vote count"
                }
            ]
        }
    },
    {
        value: "inviteDelete",
        label: "When an invite is deleted",

        event_category: "invite",
        components: {
            Guild: true,
            User: true,
            Channel: true,
            variables: [
                {
                    variable: "{invite_code}",
                    name: "invite code"
                },
                {
                    variable: "{invite_url}",
                    name: "invite url"
                },
                {
                    variable: "{invite_max_age}",
                    name: "invite max age"
                },
                {
                    variable: "{invite_max_uses}",
                    name: "invite max uses"
                },
                {
                    variable: "{invite_created_at}",
                    name: "invite created at"
                },

                {
                    variable: "{invite_uses}",
                    name: "invite uses"
                },
                {
                    variable: "{invite_expires_at}",
                    name: "invite expires at"
                }
            ]
        }
    },
    {
        value: "botUpdate",
        label: "When the bot is updated through the dashboard",
        event_category: "bot",
        server_required: true,
        components: {
            Guild: true,
            User: false,
            Member: false,
            variables: []
        }
    },
    {
        value: "guildScheduledEventCreate",
        label: "When a scheduled event is created",
        event_category: "events",

        components: {
            Guild: true,
            User: true,
            Member: false,
            variables: [
                {
                    variable: "{event_id}",
                    name: "event id"
                },
                {
                    variable: "{event_name}",
                    name: "event name"
                },
                {
                    variable: "{event_description}",
                    name: "event description"
                },
                {
                    variable: "{event_url}",
                    name: "event url"
                },
                {
                    variable: "{event_status}",
                    name: "event status"
                },
                {
                    variable: "{event_createdAt}",
                    name: "event created at"
                },
                {
                    variable: "{event_createdTimestamp}",
                    name: "event created timestamp"
                },
                {
                    variable: "{event_scheduledEndTimestamp}",
                    name: "event scheduled end timestamp"
                },
                {
                    variable: "{event_image}",
                    name: "event image"
                }
            ]
        }
    },
    {
        value: "guildScheduledEventUpdate",
        label: "When a scheduled event is updated",
        event_category: "events",

        components: {
            Guild: true,
            User: true,
            Member: false,
            variables: [
                {
                    variable: "{event_id}",
                    name: "event id"
                },
                {
                    variable: "{event_name}",
                    name: "event name"
                },
                {
                    variable: "{event_description}",
                    name: "event description"
                },
                {
                    variable: "{event_url}",
                    name: "event url"
                },
                {
                    variable: "{event_status}",
                    name: "event status"
                },
                {
                    variable: "{event_createdAt}",
                    name: "event created at"
                },
                {
                    variable: "{event_createdTimestamp}",
                    name: "event created timestamp"
                },
                {
                    variable: "{event_scheduledEndTimestamp}",
                    name: "event scheduled end timestamp"
                },
                {
                    variable: "{event_image}",
                    name: "event image"
                }
            ]
        }
    },
    {
        value: "guildScheduledEventDelete",
        label: "When a scheduled event is deleted",
        event_category: "events",

        components: {
            Guild: true,
            User: true,
            Member: false,
            variables: [
                {
                    variable: "{event_id}",
                    name: "event id"
                },
                {
                    variable: "{event_name}",
                    name: "event name"
                },
                {
                    variable: "{event_description}",
                    name: "event description"
                },
                {
                    variable: "{event_url}",
                    name: "event url"
                },
                {
                    variable: "{event_status}",
                    name: "event status"
                },
                {
                    variable: "{event_createdAt}",
                    name: "event created at"
                },
                {
                    variable: "{event_createdTimestamp}",
                    name: "event created timestamp"
                },
                {
                    variable: "{event_scheduledEndTimestamp}",
                    name: "event scheduled end timestamp"
                },
                {
                    variable: "{event_image}",
                    name: "event image"
                }
            ]
        }
    },
    {
        value: "guildScheduledEventUserAdd",
        label: "When a user joins a scheduled event",
        event_category: "events",

        components: {
            Guild: true,
            User: true,
            Member: false,
            variables: [
                {
                    variable: "{event_id}",
                    name: "event id"
                },
                {
                    variable: "{event_name}",
                    name: "event name"
                },
                {
                    variable: "{event_description}",
                    name: "event description"
                },
                {
                    variable: "{event_url}",
                    name: "event url"
                },
                {
                    variable: "{event_status}",
                    name: "event status"
                },
                {
                    variable: "{event_createdAt}",
                    name: "event created at"
                },
                {
                    variable: "{event_createdTimestamp}",
                    name: "event created timestamp"
                },
                {
                    variable: "{event_scheduledEndTimestamp}",
                    name: "event scheduled end timestamp"
                },
                {
                    variable: "{event_image}",
                    name: "event image"
                }
            ]
        }
    },
    {
        value: "guildScheduledEventUserRemove",
        label: "When a user leaves a scheduled event",
        event_category: "events",

        components: {
            Guild: true,
            User: true,
            Member: false,
            variables: [
                {
                    variable: "{event_id}",
                    name: "event id"
                },
                {
                    variable: "{event_name}",
                    name: "event name"
                },
                {
                    variable: "{event_description}",
                    name: "event description"
                },
                {
                    variable: "{event_url}",
                    name: "event url"
                },
                {
                    variable: "{event_status}",
                    name: "event status"
                },
                {
                    variable: "{event_createdAt}",
                    name: "event created at"
                },
                {
                    variable: "{event_createdTimestamp}",
                    name: "event created timestamp"
                },
                {
                    variable: "{event_scheduledEndTimestamp}",
                    name: "event scheduled end timestamp"
                },
                {
                    variable: "{event_image}",
                    name: "event image"
                }
            ]
        }
    },
    {
        value: "guildMemberBoost",
        label: "When a member boosts the server for the first time",
        event_category: "boost",
        components: {
            Guild: true,
            User: true,
            Member: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },

    {
        value: "voiceChannelJoin",

        label: "When a member joins a voice channel",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                },
                {
                    variable: "{event_channel_name}",
                    name: "channel name"
                },
                {
                    variable: "{event_channel_id}",
                    name: "channel id"
                },
                {
                    variable: "{event_channel_parent_id}",
                    name: "channel parent id"
                },
                {
                    variable: "{event_channel_user_limit}",
                    name: "channel user limit"
                },
                {
                    variable: "{event_channel_full}",
                    name: "channel full"
                }
            ]
        }
    },

    {
        value: "voiceChannelSwitch",

        label: "When a member switches voice channels",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                },
                {
                    variable: "{event_old_channel_id}",
                    name: "old channel id"
                },
                {
                    variable: "{event_old_channel_name}",
                    name: "old channel name"
                },
                {
                    variable: "{event_old_channel_parent_id}",
                    name: "old channel parent id"
                },
                {
                    variable: "{event_old_channel_user_limit}",
                    name: "old channel user limit"
                },
                {
                    variable: "{event_old_channel_full}",
                    name: "old channel full"
                },
                {
                    variable: "{event_new_channel_id}",
                    name: "new channel id"
                },
                {
                    variable: "{event_new_channel_name}",
                    name: "new channel name"
                },
                {
                    variable: "{event_new_channel_parent_id}",
                    name: "new channel parent id"
                },
                {
                    variable: "{event_new_channel_user_limit}",
                    name: "new channel user limit"
                },
                {
                    variable: "{event_new_channel_full}",
                    name: "new channel full"
                }
            ]
        }
    },

    {
        value: "voiceChannelLeave",

        label: "When a member leaves a voice channel",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                },
                {
                    variable: "{event_channel_name}",
                    name: "channel name"
                },
                {
                    variable: "{event_channel_id}",
                    name: "channel id"
                },
                {
                    variable: "{event_channel_parent_id}",
                    name: "channel parent id"
                },
                {
                    variable: "{event_channel_user_limit}",
                    name: "channel user limit"
                },
                {
                    variable: "{event_channel_full}",
                    name: "channel full"
                }
            ]
        }
    },
    {
        value: "serverMute",
        label: "When a member is server muted",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },
    {
        value: "selfMute",
        label: "When a member self mutes",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },

    {
        value: "serverUnMute",
        label: "When a member is server unmuted",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },
    {
        value: "selfUnMute",
        label: "When a member self unmutes",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },

    {
        value: "serverDeaf",
        label: "When a member is server deafened",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },
    {
        value: "selfDeaf",
        label: "When a member self deafens",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },

    {
        value: "serverUnDeaf",
        label: "When a member is server undeafened",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },
    {
        value: "selfUnDeaf",
        label: "When a member self undeafens",
        event_category: "voice",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Channel: false,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },
    {
        value: "guildMemberUnBoost",
        label: "When a member unboosts the server",
        event_category: "boost",
        components: {
            Guild: true,
            User: true,
            Member: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },

    {
        value: "guildBoostLevelUp",
        label: "When the server boost level increases",
        event_category: "boost",
        components: {
            Guild: true,
            User: false,
            Member: false,
            variables: [
                // {
                // 	variable: "{event_oldLevel}",
                // 	value: oldLevel
                // },
                // {
                // 	variable: "{event_newLevel}",
                // 	value: newLevel
                // }
                {
                    variable: "{event_oldLevel}",
                    name: "old level"
                },
                {
                    variable: "{event_newLevel}",
                    name: "new level"
                }
            ]
        }
    },

    {
        value: "guildBoostLevelDown",
        label: "When the server boost level decreases",
        event_category: "boost",
        components: {
            Guild: true,
            User: false,
            Member: false,
            variables: [
                // {
                // 	variable: "{event_oldLevel}",
                // 	value: oldLevel
                // },
                // {
                // 	variable: "{event_newLevel}",
                // 	value: newLevel
                // }
                {
                    variable: "{event_oldLevel}",
                    name: "old level"
                },
                {
                    variable: "{event_newLevel}",
                    name: "new level"
                }
            ]
        }
    },
    {
        value: "messageCreate",
        label: "When a new message is sent",
        event_category: "message",
        components: {
            Guild: true,
            Channel: true,
            User: true,
            Member: true,
            Message: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                },
                {
                    variable: "{event_message_content}",
                    name: "message content"
                },
                {
                    variable: "{event_message_id}",
                    name: "message id"
                },
                {
                    variable: "{event_message_channel}",
                    name: "message channel"
                },
                {
                    variable: "{event_message_channel_type}",
                    name: "message channel type"
                },
                {
                    variable: "{event_message_channel_id}",
                    name: "message channel id"
                },
                {
                    variable: "{event_message_createdAt}",
                    name: "message created at"
                },
                {
                    variable: "{event_message_attachments}",
                    name: "message attachments"
                },
                {
                    variable: "{event_message_attachments_count}",
                    name: "message attachments count"
                },

                {
                    variable: "{event_message_has_attachments}",
                    name: "message has attachments"
                },
                {
                    variable: "{event_message_attachment_1}",
                    name: "message attachment 1"
                },
                {
                    variable: "{event_message_attachment_n}",
                    name: "message attachment n"
                }
            ]
        }
    },
    {
        value: "messageUpdate",
        label: "When a message is updated or edited",
        event_category: "message",
        components: {
            Guild: true,
            Channel: true,
            User: true,
            Member: true,
            Message: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                },
                {
                    variable: "{event_oldMessage_content}",
                    name: "old message content"
                },
                {
                    variable: "{event_newMessage_content}",
                    name: "new message content"
                },
                {
                    variable: "{event_message_id}",
                    name: "message id"
                },
                {
                    variable: "{event_message_channel}",
                    name: "message channel"
                },
                {
                    variable: "{event_message_channel_id}",
                    name: "message channel id"
                },
                {
                    variable: "{event_message_createdAt}",
                    name: "message created at"
                },
                {
                    variable: "{event_message_has_attachments}",
                    name: "message has attachments"
                },
                {
                    variable: "{event_message_attachment_1}",
                    name: "message attachment 1"
                },
                {
                    variable: "{event_message_attachment_n}",
                    name: "message attachment n"
                },
                {
                    variable: "{event_message_attachments_count}",
                    name: "message attachments count"
                }
            ]
        }
    },
    {
        value: "messageDelete",
        label: "When a message is deleted",
        event_category: "message",
        components: {
            Guild: true,
            Channel: true,
            User: true,
            Member: true,
            Message: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                },
                {
                    variable: "{event_message_content}",
                    name: "message content"
                },
                {
                    variable: "{event_message_id}",
                    name: "message id"
                },
                {
                    variable: "{event_message_channel}",
                    name: "message channel"
                },
                {
                    variable: "{event_message_channel_id}",
                    name: "message channel id"
                },
                {
                    variable: "{event_message_createdAt}",
                    name: "message created at"
                },
                {
                    variable: "{event_message_attachment}",
                    name: "message attachment"
                },
                {
                    variable: "{event_message_attachments}",
                    name: "message attachments"
                },
                {
                    variable: "{event_message_attachments_count}",
                    name: "message attachments count"
                },

                {
                    variable: "{event_message_has_attachments}",
                    name: "message has attachments"
                },
                {
                    variable: "{event_message_attachment_1}",
                    name: "message attachment 1"
                },
                {
                    variable: "{event_message_attachment_n}",
                    name: "message attachment n"
                }
            ]
        }
    },
    {
        value: "messagePinned",
        label: "When a message is pinned",
        event_category: "message",
        components: {
            Guild: true,
            Channel: true,
            User: true,
            Member: true,
            Message: true,
            variables: []
        }
    },

    {
        value: "threadCreate",
        label: "When a thread is created",
        event_category: "threads",

        components: {
            Guild: true,
            Channel: true,
            variables: [
                {
                    variable: "{new_thread}",
                    name: "is the thread new? (true/false)"
                },
                {
                    variable: "{event_thread_id}",
                    name: "thread id"
                },
                {
                    variable: "{event_thread_name}",
                    name: "thread name"
                },
                {
                    variable: "{event_created_at}",
                    name: "thread created at"
                },
                {
                    variable: "{event_thread_createdTimestamp}",
                    name: "thread created timestamp"
                },
                {
                    variable: "{event_creator_id}",
                    name: "thread creator id"
                },
                {
                    variable: "{event_parent_channel_id}",
                    name: "thread parent channel id"
                }
            ]
        },
        options: {
            channel: "thread that was created."
        }
    },
    {
        value: "threadUpdate",
        label: "When a thread is updated",
        event_category: "threads",

        components: {
            Guild: true,
            Channel: true,
            variables: [
                {
                    variable: "{event_thread_id}",
                    name: "thread id"
                },
                {
                    variable: "{old_event_thread_name}",
                    name: "old thread name"
                },
                {
                    variable: "{new_event_thread_name}",
                    name: "new thread name"
                },
                {
                    variable: "{old_thread_archived}",
                    name: "old thread archived"
                },
                {
                    variable: "{new_thread_archived}",
                    name: "new thread archived"
                },
                {
                    variable: "{old_thread_locked}",
                    name: "old thread locked"
                },
                {
                    variable: "{new_thread_locked}",
                    name: "new thread locked"
                },
                {
                    variable: "{event_created_at}",
                    name: "thread created at"
                },
                {
                    variable: "{event_thread_createdTimestamp}",
                    name: "thread created timestamp"
                },
                {
                    variable: "{event_creator_id}",
                    name: "thread creator id"
                },
                {
                    variable: "{event_parent_channel_id}",
                    name: "thread parent channel id"
                }
            ]
        },
        options: {
            channel: "thread that was created."
        }
    },
    {
        value: "threadDelete",
        label: "When a thread is deleted",
        event_category: "threads",

        components: {
            Guild: true,
            variables: [
                {
                    variable: "{event_thread_id}",
                    name: "thread id"
                },
                {
                    variable: "{event_thread_name}",
                    name: "thread name"
                },
                {
                    variable: "{event_created_at}",
                    name: "thread created at"
                },
                {
                    variable: "{event_thread_createdTimestamp}",
                    name: "thread created timestamp"
                },
                {
                    variable: "{event_creator_id}",
                    name: "thread creator id"
                },
                {
                    variable: "{event_parent_channel_id}",
                    name: "thread parent channel id"
                }
            ]
        },
        options: {
            channel: "thread that was created."
        }
    },
    {
        value: "channelCreate",
        label: "When a channel is created",
        event_category: "channel",
        components: {
            Guild: true,
            Channel: true,
            variables: [
                {
                    name: "channel name",
                    variable: "{event_channel_name}"
                },
                {
                    name: "channel id",
                    variable: "{event_channel_id}"
                },
                {
                    variable: "{event_channel_category_id}",
                    name: "channel category id"
                },
                {
                    variable: "{event_channel_type}",
                    name: "channel type"
                },
                {
                    variable: "{event_channel_createdAt}",
                    name: "channel created at"
                },
                // nsfw,
                {
                    variable: "{event_channel_nsfw}",
                    name: "channel nsfw"
                }
            ]
        },
        options: {
            channel: "channel that was created."
        }
    },
    {
        value: "channelUpdate",
        label: "When a channel is updated",
        event_category: "channel",
        components: {
            Guild: true,
            Channel: true,
            variables: [
                {
                    variable: "{event_oldChannel_name}",
                    name: "old channel name"
                },
                {
                    variable: "{event_oldChannel_id}",
                    name: "old channel id"
                },
                {
                    variable: "{event_oldChannel_category_id}",
                    name: "old channel category id"
                },
                {
                    variable: "{event_oldChannel_type}",
                    name: "old channel type"
                },
                {
                    variable: "{event_oldChannel_createdAt}",
                    name: "old channel created at"
                },
                {
                    variable: "{event_newChannel_name}",
                    name: "new channel name"
                },
                {
                    variable: "{event_newChannel_id}",
                    name: "new channel id"
                },
                {
                    variable: "{event_newChannel_category_id}",
                    name: "new channel category id"
                },
                {
                    variable: "{event_newChannel_type}",
                    name: "new channel type"
                },
                {
                    variable: "{event_newChannel_createdAt}",
                    name: "new channel created at"
                },
                // nsfw,
                {
                    variable: "{event_oldChannel_nsfw}",
                    name: "old channel nsfw"
                },
                {
                    variable: "{event_newChannel_nsfw}",
                    name: "new channel nsfw"
                }
            ]
        },
        options: {
            channel: "channel that was created."
        }
    },
    {
        value: "channelDelete",
        label: "When a channel is deleted",
        event_category: "channel",
        components: {
            Guild: true,
            Channel: true,
            variables: [
                {
                    name: "channel name",
                    variable: "{event_channel_name}"
                },
                {
                    name: "channel id",
                    variable: "{event_channel_id}"
                },
                {
                    variable: "{event_channel_category_id}",
                    name: "channel category id"
                },
                {
                    variable: "{event_channel_type}",
                    name: "channel type"
                },
                {
                    variable: "{event_channel_createdAt}",
                    name: "channel created at"
                },
                // nsfw,
                {
                    variable: "{event_channel_nsfw}",
                    name: "channel nsfw"
                }
            ]
        },
        options: {
            channel: "channel that was deleted."
        }
    },

    {
        value: "guildChannelPermissionsUpdate",
        label: "When a channel's permissions are updated",
        event_category: "channel",
        components: {
            Guild: true,
            Channel: true,
            variables: [
                // {
                // 	variable: "{event_oldPermissions}",
                // 	value: oldPermissions
                // },
                // {
                // 	variable: "{event_newPermissions}",
                // 	value: newPermissions
                // }
                // {
                // 	variable: "{event_oldPermissions}",
                // 	name: "old permissions"
                // },
                // {
                // 	variable: "{event_newPermissions}",
                // 	name: "new permissions"
                // }
            ]
        },
        options: {
            channel: "channel that was deleted."
        }
    },
    {
        value: "guildMemberAdd",
        label: "When a new user joins the server",
        event_category: "member",
        components: {
            Guild: true,
            User: true,
            Member: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                }
            ]
        }
    },
    {
        value: "guildBanAdd",
        label: "When a user is banned from the server",
        event_category: "member",
        components: {
            Guild: true,
            User: true,
            variables: [
                {
                    variable: "{event_reason}",
                    name: "reason"
                },
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                }
            ]
        }
    },
    {
        value: "guildBanRemove",
        label: "When a user is unbanned from the server",
        event_category: "member",
        components: {
            Guild: true,
            User: true,
            variables: [
                {
                    variable: "{event_reason}",
                    name: "reason"
                }
            ]
        }
    },
    {
        value: "guildMemberRemove",
        label: "When a user leaves or is kicked from the server",
        event_category: "member",
        components: {
            Guild: true,
            User: true,
            Member: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                }
            ]
        }
    },
    {
        value: "guildMemberRoleAdd",
        label: "When a role is added to a user",
        event_category: "member",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Role: true,
            variables: [
                {
                    variable: "{event_role_id}",
                    name: "role id"
                },
                {
                    variable: "{event_role_name}",
                    name: "role name"
                },
                {
                    variable: "{event_role}",
                    name: "role"
                },
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                }
            ]
        }
    },
    {
        value: "guildMemberRoleRemove",
        label: "When a role is removed from a user",
        event_category: "member",
        components: {
            Guild: true,
            User: true,
            Member: true,
            Role: true,
            variables: [
                {
                    variable: "{event_role_id}",
                    name: "role id"
                },
                {
                    variable: "{event_role_name}",
                    name: "role name"
                },
                {
                    variable: "{event_role}",
                    name: "role"
                },
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                }
            ]
        }
    },
    {
        value: "guildMemberUpdate",
        label: "When a guild member is updated",
        event_category: "member",
        components: {
            Guild: true,
            User: true,
            Member: true,
            variables: [
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                },
                {
                    variable: "{event_old_nickname}",
                    name: "old nickname"
                },
                {
                    variable: "{event_new_nickname}",
                    name: "new nickname"
                },
                {
                    variable: "{event_old_discriminator}",
                    name: "old discriminator"
                },
                {
                    variable: "{event_new_discriminator}",
                    name: "new discriminator"
                },
                {
                    variable: "{event_old_roles}",
                    name: "old roles"
                },
                {
                    variable: "{event_new_roles}",
                    name: "new roles"
                },
                {
                    variable: "{event_member_verified_update}",
                    name: "member verified update"
                }
            ]
        }
    },
    {
        value: "guildMemberNicknameUpdate",
        label: "When a user changes their nickname",
        event_category: "member",
        components: {
            Guild: true,
            User: true,
            Member: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                },
                {
                    variable: "{event_old_nickname}",
                    name: "old nickname"
                },
                {
                    variable: "{event_new_nickname}",
                    name: "new nickname"
                }
            ]
        }
    },
    {
        value: "presenceUpdate",
        label: "When a user's status changes",
        event_category: "member",

        components: {
            Guild: true,
            User: true,
            Member: true,
            variables: [
                {
                    variable: "{event_old_status}",
                    name: "old status"
                },
                {
                    variable: "{event_new_status}",
                    name: "new status"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_name}",
                    name: "user username"
                },
                {
                    variable: "{event_user_display_name}",
                    name: "user display name"
                }
            ]
        }
    },
    {
        value: "roleCreate",
        label: "When a role is created",
        event_category: "role",
        components: {
            Guild: true,
            User: false,
            Member: false,
            Role: true,
            variables: [
                {
                    variable: "{event_role_id}",
                    name: "role id"
                },
                {
                    variable: "{event_role_name}",
                    name: "role name"
                },
                {
                    variable: "{event_role}",
                    name: "role"
                },
                {
                    variable: "{event_role_hex_color}",
                    name: "role hex color"
                },
                {
                    variable: "{event_role_iconURL}",
                    name: "role icon url"
                },
                {
                    variable: "{event_role_hoisted}",
                    name: "role hoisted"
                },
                // position
                {
                    variable: "{event_role_position}",
                    name: "role position"
                }
            ]
        }
    },

    {
        value: "roleUpdate",
        label: "When a role is updated",
        event_category: "role",
        components: {
            Guild: true,
            User: false,
            Member: false,
            Role: true,
            variables: [
                {
                    variable: "{event_role_id}",
                    name: "role id"
                },
                {
                    variable: "{event_role_newName}",
                    name: "new role name"
                },
                {
                    variable: "{event_role_oldName}",
                    name: "old role name"
                },
                {
                    variable: "{event_role_newColor}",
                    name: "new role color"
                },
                {
                    variable: "{event_role_oldColor}",
                    name: "old role color"
                },
                {
                    variable: "{event_role}",
                    name: "role"
                },
                {
                    variable: "{event_role_newHoisted}",
                    name: "new role hoisted"
                },
                {
                    variable: "{event_role_oldHoisted}",
                    name: "old role hoisted"
                },
                {
                    variable: "{event_role_newIconURL}",
                    name: "new role icon url"
                },
                {
                    variable: "{event_role_oldIconURL}",
                    name: "old role icon url"
                },
                {
                    variable: "{event_role_newPosition}",
                    name: "new role position"
                },
                {
                    variable: "{event_role_oldPosition}",
                    name: "old role position"
                }
            ]
        }
    },
    {
        value: "roleDelete",
        label: "When a role is deleted",
        event_category: "role",
        components: {
            Guild: true,
            User: false,
            Member: false,
            Role: true,
            variables: [
                {
                    variable: "{event_role_id}",
                    name: "role id"
                },
                {
                    variable: "{event_role_name}",
                    name: "role name"
                },
                {
                    variable: "{event_role}",
                    name: "role"
                },
                {
                    variable: "{event_role_hex_color}",
                    name: "role hex color"
                },
                {
                    variable: "{event_role_iconURL}",
                    name: "role icon url"
                },
                {
                    variable: "{event_role_hoisted}",
                    name: "role hoisted"
                },
                // position
                {
                    variable: "{event_role_position}",
                    name: "role position"
                }
            ]
        }
    },
    {
        value: "messageReactionAdd",
        label: "When a reaction is added to a message",
        event_category: "reaction",
        components: {
            Guild: true,
            User: true,
            Channel: true,
            Member: true,
            Message: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                },
                {
                    variable: "{event_reaction_count}",
                    name: "reaction count"
                },
                {
                    variable: "{event_reaction_emoji}",
                    name: "reaction emoji"
                },
                {
                    variable: "{event_reaction_emoji_name}",
                    name: "reaction emoji name"
                },
                {
                    variable: "{event_reaction_emoji_id}",
                    name: "reaction emoji id"
                },
                {
                    variable: "{event_message_content}",
                    name: "message content"
                },
                {
                    variable: "{event_message_id}",
                    name: "message id"
                },
                {
                    variable: "{event_message_channel}",
                    name: "message channel"
                },
                {
                    variable: "{event_message_channel_id}",
                    name: "message channel id"
                },
                {
                    variable: "{event_message_createdAt}",
                    name: "message created at"
                }
            ]
        }
    },
    {
        value: "messageReactionRemove",
        label: "When a reaction is removed from a message",
        event_category: "reaction",
        components: {
            Guild: true,
            User: true,
            Channel: true,
            Member: true,
            Message: true,
            variables: [
                {
                    variable: "{event_user_name}",
                    name: "user name"
                },
                {
                    variable: "{event_user_id}",
                    name: "user id"
                },
                {
                    variable: "{event_user_tag}",
                    name: "user tag"
                },
                {
                    variable: "{event_user_avatar}",
                    name: "user avatar"
                },
                {
                    variable: "{event_reaction_count}",
                    name: "reaction count"
                },
                {
                    variable: "{event_reaction_emoji}",
                    name: "reaction emoji"
                },
                {
                    variable: "{event_reaction_emoji_name}",
                    name: "reaction emoji name"
                },
                {
                    variable: "{event_reaction_emoji_id}",
                    name: "reaction emoji id"
                },
                {
                    variable: "{event_message_content}",
                    name: "message content"
                },
                {
                    variable: "{event_message_id}",
                    name: "message id"
                },
                {
                    variable: "{event_message_channel}",
                    name: "message channel"
                },
                {
                    variable: "{event_message_channel_id}",
                    name: "message channel id"
                },
                {
                    variable: "{event_message_createdAt}",
                    name: "message created at"
                }
            ]
        }
    }
];

export const ACTION_TYPES = [
    { icon: faHourglassEnd, title: "Run a Loop", beta: true, value: "loop", label: "Loop through actions and conditions a set amount of times" },
    { icon: faHourglassHalf, title: "Stop a Loop", value: "break_loop", label: "Break out of and stop any active loop" },

    {
        icon: faReply,
        value: "plain_text",
        title: "Plain Text Reply",
        label: "Bot replies with a plain text response",
        requriements: [["Interaction"], ["Channel"]]
    },
    {
        icon: faCodeBranch,
        title: "Embed Reply",
        value: "embed",
        label: "Bot replies with an embed response",
        requriements: [["Interaction"], ["Channel"]]
    },
    { icon: faTrashAlt, value: "purge_messages", title: "Purge Messages", label: "Purge messages in a channel", requirements: [] },
    {
        icon: faFingerprint,
        title: "Set a unique variable",
        value: "set_unique_variable",
        label: "Set a unique variable for every execution of this command or event.",
        requriements: []
    },
    { icon: faBullhorn, title: "Change the Bot's Status", value: "status_change", label: "Change the bot's status or presence", requirements: [] },

    {
        icon: faReplyAll,
        title: "Random Reply",
        value: "random_response",
        label: "Bot responds with a random response",
        requriements: [["Interaction"], ["Channel"]]
    },
    {
        icon: faPaperPlane,
        title: "Send a message to a Channel",
        value: "targeted_response",
        label: "Bot sends a message to a specific channel",
        disabled: false
    },
    {
        icon: faEnvelope,
        title: "Direct Message a User",
        value: "dm_response",
        label: "Bot sends a direct message to a member",
        disabled: false
    },
    {
        icon: faMousePointer,
        title: "Send a Message with attached Buttons",
        value: "button_response",
        label: "Add buttons and button actions",
        button: false
    },
    {
        icon: faUserPlus,
        title: "Add Roles",
        value: "role_add",
        label: "Adds one or more roles to a user",
        requirements: ["Member"]
    },
    {
        icon: faUserMinus,
        title: "Remove Roles",
        value: "role_remove",
        label: "Removes one or more roles from a user",
        requirements: ["Member"]
    },
    {
        icon: faHourglass,
        title: "Wait before running another action",
        value: "wait_action",
        label: "Wait before executing another action"
    },
    {
        icon: faHourglass,
        title: "Wait before running another action",
        value: "wait_action",
        label: "Wait before executing another action"
    },
    { icon: faExclamation, title: "Send an error log message", value: "send_error_message", label: "Trigger an error message" },

    { icon: faComment, value: "channel_create", title: "Create a Channel", label: "Creates a channel in the server", requirements: ["Guild"] },
    { icon: faEdit, value: "channel_edit", title: "Edit a Channel", label: "Edits a channel in the server", requirements: ["Guild"] },
    {
        icon: faCommentSlash,
        value: "channel_delete",
        title: "Delete a Channel",
        label: "Deletes a channel in the server",
        requirements: ["Guild"]
    },
    { icon: faComment, value: "thread_create", title: "Create a Thread", label: "Create a public or private thread in the server", requirements: ["Guild"] },
    { icon: faEdit, value: "thread_edit", title: "Edit a Thread", label: "Edit a public or private thread in the server", requirements: ["Guild"] },
    { icon: faCommentSlash, value: "thread_delete", title: "Delete a Thread", label: "Delete a public or private thread in the server", requirements: ["Guild"] },
    {
        icon: faUserSlash,
        value: "kick_action",
        title: "Kick a Member",
        label: "Kick a member from the guild"
    },
    {
        icon: faUserTimes,
        value: "ban_action",
        title: "Ban a Member",
        label: "Ban a member from the guild"
    },
    {
        icon: faUserFriends,
        title: "Create a role",
        value: "create_role",
        label: "Creates a role in the server",
        requirements: ["Guild"]
    },
    {
        icon: faUsersSlash,
        title: "Delete a role",
        value: "delete_role",
        label: "Deletes a role in the server",
        requirements: ["Guild"]
    },
    { icon: faUserCog, value: "edit_role", title: "Edit a Role", label: "Edits a role in the server", requirements: ["Guild"] },
    {
        icon: faHourglassEnd,
        value: "timeout_action",
        title: "Timeout a Member",
        label: "Timeout a member in the server"
    },
    {
        icon: faMoneyBill,
        title: "Add Currency",
        value: "add_currency",
        label: "Adds currency to a users balance"
    },
    {
        icon: faMoneyBillAlt,
        title: "Remove Currency",
        value: "remove_currency",
        label: "Removes currency from a users balance"
    },
    { icon: faCartPlus, title: "Add Item", value: "add_item", label: "Adds an item to a users inventory" },
    { icon: faCartArrowDown, title: "Remove Item", value: "remove_item", label: "Removes an item from a users inventory." },
    { icon: faBars, title: "Send a Message with a Select Menu", value: "select_menu", label: "Create a select menu with attached actions", button: false },
    { icon: faTrash, title: "Delete a Message", value: "delete_message", label: "Delete a message or reply" },
    { icon: faRandom, title: "Publish a Message", value: "crosspost_message", label: "Publish a message sent in an announcement channel" },
    { icon: faEquals, title: "Set Variable", value: "set_var", label: "Set the value of a custom variable.", button: false },
    { icon: faCalculator, title: "Run Equation on Variable", value: "math_var", label: "Run an equation on a custom variable.", button: false },
    { icon: faTimes, title: "Delete Variable", value: "delete_var", label: "Delete the value of a custom variable.", button: false },
    { icon: faCode, title: "Send an API Request", value: "api_action", label: "Send a HTTP request to an external API.", button: false },
    { icon: faEdit, value: "change_nickname", title: "Change a Members Nickname", label: "Change a member nickname in the server." },
    { icon: faMapPin, value: "pin_message", title: "Pin a Message", label: "Pin a message or reply sent in this command." },
    { icon: faTable, title: "Send a Form", value: "modal", label: "Send a form or modal and wait for a response", button: false },
    { icon: faCode, title: "Execute an IFTTT Trigger", value: "ifttt_action", label: "Execute an IFTTT trigger and any associated applets", button: false },
    { beta: true, icon: faUserPlus, value: "create_invite", title: "Create Server Invite", label: "Create an invite for the server" }
];