import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import NewHomeNav from '../elements/NewHomeNav';
import { Helmet } from 'react-helmet';
import ToolInput from './components/ToolInput';
import ToolTextArea from './components/ToolTextArea';
import DiscordEmbed from './components/DiscordEmbed';
import DiscordEmbedPreview from './components/DiscordEmbedPreview';
import * as deepcopy from 'deepcopy';
import axios from "axios";
import ReactJson from 'react-json-view';
import { Dialog, Transition } from '@headlessui/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast, { Toaster } from "react-hot-toast";


export class EmbedBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            webhook: '',
            avatarURL: "",
            username: "",
            content: "",
            embeds: [],
            openEmbed: null,
            embedJSON: {},
            open: false,
            valid: false,
            copied: false
        };
    }


    formatEmbedJSON = (stringCheck) => {
        // Convert all state fields to correct JSON for the Discord Embeds
        var finalJSON = {
        };

        if (this.state.content) finalJSON.content = this.state.content;
        if (this.state.username) finalJSON.username = this.state.username;
        if (this.state.avatarURL) finalJSON.avatar_url = this.state.avatarURL;

        if (this.state.embeds.length > 0) {
            finalJSON.embeds = [];
            this.state.embeds.forEach((embed, index) => {
                var embedJSON = {

                };
                if (embed.title) embedJSON.title = embed.title;
                if (embed.description) embedJSON.description = embed.description;
                if (embed.color) {
                    var color = parseInt(embed.color.replace('#', ''), 16);
                    embedJSON.color = color;
                }
                if (embed.url) embedJSON.url = embed.url;
                if (embed.timestamp) {
                    var timestamp = new Date(embed.timestamp);
                    embedJSON.timestamp = timestamp.toISOString();

                }
                if (embed.footer && embed.footer.text) {
                    embedJSON.footer = {
                        text: embed.footer.text
                    };
                    if (embed.footer.icon_url) embedJSON.footer.icon_url = embed.footer.icon_url;

                }
                if (embed.image && embed.image.url) {
                    embedJSON.image = {
                        url: embed.image.url
                    };
                }
                if (embed.thumbnail && embed.thumbnail.url) {
                    embedJSON.thumbnail = {
                        url: embed.thumbnail.url
                    };
                }
                if (embed.author && embed.author.name) {
                    embedJSON.author = {
                        name: embed.author.name
                    };
                    if (embed.author.url) embedJSON.author.url = embed.author.url;
                    if (embed.author.icon_url) embedJSON.author.icon_url = embed.author.icon_url;
                }

                if (embed.fields.length > 0) {
                    embedJSON.fields = [];
                    embed.fields.forEach(field => {
                        embedJSON.fields.push({
                            name: field.name,
                            value: field.value,
                            inline: field.inline
                        });
                    });
                }

                finalJSON.embeds.push(embedJSON);
            });
        }

        // this.setState({ embedJSON: embedJSON });
        if (stringCheck) {
            return JSON.stringify(finalJSON, null, 4);
        }
        return finalJSON;
        console.log(finalJSON, 'EMBED JSON');


    };

    componentDidUpdate(prevProps, prevState) {

    }


    postWebhook = async () => {
        var JSON = this.formatEmbedJSON();
        console.log(JSON, 'JSON');
        var response = await axios.post(this.state.webhook, JSON).catch(e => {
            console.log(e, e.message);
            toast.error(e.message, {
                duration: 5000,
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff"
                }
            });
        });
        if (response) {
            toast.success('Message sent', {
                duration: 5000,
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff"
                }
            });
        }
    };



    renderEmbeds = () => {
        var embeds = [];


        this.state.embeds.forEach((embed, index) => {
            embeds.push(
                <DiscordEmbed index={index} open={this.state.openEmbed === index} copyEmbed={() => {
                    if (this.state.embeds.length >= 10) return;
                    var embedCopy = deepcopy(embed);
                    var embeds = [...this.state.embeds];
                    embeds.push(embedCopy);
                    this.setState({ embeds: embeds });
                }} onChange={(updatedEmbed) => {
                    var embeds = [...this.state.embeds];
                    console.log(updatedEmbed, 'UPDATED EMBED');
                    embeds[index] = updatedEmbed;
                    this.setState({ embeds: embeds });
                }} openClose={() => {
                    if (this.state.openEmbed === index) {
                        this.setState({ openEmbed: null });
                    } else {
                        this.setState({ openEmbed: index });
                    }
                }} delete={() => {
                    var embeds = [...this.state.embeds];
                    embeds.splice(index, 1);
                    this.setState({ embeds: embeds });
                }} embed={embed} key={index}></DiscordEmbed>
            );
        });

        return embeds;
    };
    render() {
        return (

            <>
                <Toaster position="top-right" reverseOrder={false} />
                <Helmet>
                    <title>Discord Embed Builder | BotGhost | Free Discord Bot Maker</title>
                    <meta name="description" content={'Build, preview and send Discord embed messages from a simple, customizable GUI.'} />
                </Helmet>
                <NewHomeNav></NewHomeNav>




                <Transition.Root show={this.state.open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => {
                        this.setState({ open: false });
                    }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg md:mx-48 min-h-full bg-lightGray p-3 text-left shadow-xl transition-all  w-full ">
                                        <div className="flex justify-center">
                                            <div className="text-center ml-2">
                                                <Dialog.Title as="h3" className="text-2xl text-white font-bold leading-6">
                                                    Message JSON
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                </div>
                                            </div>
                                        </div>

                                        <div className='md:px-5 md:py-2 rounded-sm break-all'>
                                            <ReactJson enableClipboard={false} displayDataTypes={false} displayObjectSize={false} name={null} theme="shapeshifter" className="min-h-[40px]" src={this.formatEmbedJSON()} />
                                        </div>

                                        <div className="mt-2 sm:flex sm:flex-row-reverse">

                                            <CopyToClipboard onCopy={() => {
                                                this.setState({ copied: true });
                                                setTimeout(() => {
                                                    this.setState({ copied: false });
                                                }, 1000);
                                            }} text={this.formatEmbedJSON(true)}>
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"

                                                >
                                                    Copy JSON
                                                </button>
                                            </CopyToClipboard>
                                            <div className='flex justify-center'>
                                                {this.state.copied ? <span className='text-red font-bold'>Copied!</span> : null}
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                <main className='min-h-[100vh] bg-darkGray pt-20 pb-20'>

                    <div className='container'>
                        <div className="text-center text-white flex justify-center flex-col mb-3">
                            <img className='h-[60px]' src={process.env.PUBLIC_URL + '/img/logo-red.png'}></img>
                            <h1 className='text-5xl font-bold'>Discord Embed Builder</h1>
                            <p className='muted-text'>Easily create and send discord embeds and messages through webhooks.</p>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 px-2 md:grid-cols-2 md:!px-5'>

                        <section className='grid gap-y-2'>

                            {/* Webhook */}
                            <div>
                                <ToolInput label="Webhook" value={this.state.webhook} onChange={(value) => {
                                    this.setState({ webhook: value });
                                }}></ToolInput>
                            </div>

                            <div className='grid grid-cols-2 gap-x-3 '>
                                <ToolInput value={this.state.username} maxLength={80} label="Username" onChange={(value) => {
                                    this.setState({ username: value });
                                }}></ToolInput>
                                <ToolInput value={this.state.avatarURL} label="Avatar URL" onChange={(value) => {
                                    this.setState({ avatarURL: value });
                                }}></ToolInput>
                            </div>

                            <div className='flex mt-2'>

                                <div className='flex justify-center ' >
                                    <button className='btn btn-gray mr-2 px-2 py-2 font-bold text-white' onClick={() => {
                                        this.setState({
                                            open: true
                                        });

                                    }}>View JSON</button>
                                </div>
                                <div className='ml-auto'>
                                    <button className='btn btn-gray mr-2 px-2 py-2 font-bold text-white' onClick={() => {
                                        this.setState({
                                            avatarURL: "",
                                            username: "",
                                            content: "",
                                            embeds: []
                                        });

                                    }}>Clear Message</button>
                                    <button disabled={!this.state.webhook} className='btn btn-red mr-2 px-2 py-2 font-bold text-white' onClick={() => {
                                        this.postWebhook();
                                    }}>Send Message</button>
                                </div>
                            </div>


                            <div className='border-t-[1px] border-[#adb5bd] mb-2 mt-3'></div>

                            {/* Content */}
                            <div>

                                <div className='mt-3'>
                                    <ToolTextArea value={this.state.content} maxLength={2000} label=" Message Content" onChange={(value) => {
                                        this.setState({ content: value });
                                    }} />
                                </div>


                            </div>
                            <div className='border-t-[1px] border-[#adb5bd] mb-2 mt-3'></div>

                            {/* Embeds */}

                            <div>
                                <label htmlFor="email" className="block text-md font-bold leading-6 text-white">
                                    Embeds <span className='muted-text opacity-50'>{this.state.embeds.length}/10</span>
                                </label>

                                <div>
                                    {this.renderEmbeds()}
                                </div>

                                <div className='mt-3'>
                                    <button className='btn btn-red mr-2 px-2 py-2 font-bold' onClick={() => {
                                        if (this.state.embeds.length >= 10) return;
                                        var newEmbed = {
                                            title: "",
                                            description: "",
                                            author: {
                                                name: "",
                                                url: "",
                                                icon_url: ""
                                            },
                                            color: "",
                                            footer: "",
                                            footerIcon: "",
                                            timestamp: "",
                                            image: {
                                                url: ""
                                            },
                                            thumbnail: {
                                                url: ""
                                            },
                                            fields: []
                                        };

                                        var embeds = this.state.embeds;
                                        embeds.push(newEmbed);
                                        this.setState({ embeds: embeds });
                                    }}>Add Embed</button>
                                    <button className='btn btn-gray px-2 py-2 font-bold' onClick={() => {
                                        this.setState({ embeds: [] });
                                    }}>Clear Embeds</button>
                                </div>
                            </div>

                        </section>


                        <section className='md:ml-5'>

                            <DiscordEmbedPreview
                                content={this.state.content}
                                username={this.state.username}
                                avatarURL={this.state.avatarURL}
                                embeds={this.state.embeds}
                            />
                        </section>
                    </div>
                </main>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default EmbedBuilder;