import React, { Component } from 'react';

export class TutorialsSection extends Component {
    render() {

        const tutorials = [
            {
                title: "How To Make A Discord Bot Without Coding [2024]",
                image: "https://usenotioncms.com/proxy/block/f582eb8c-492a-42f8-af64-9107aa448126%2F7e828011-4db4-4b1c-8bb4-1b3758a7ea34%2Fmaxresdefault-1-1024x576.jpg",
                link: "https://botghost.com/community/how-to-make-a-discord-bot"
            },
            {
                title: "How to Create a Discord Ticket Bot with BotGhost",
                image: "https://img.notionusercontent.com/s3/prod-files-secure%2Fdfd1a17d-3e25-4f7e-8427-bdc0ad8b82c3%2F658d2e37-d00f-45f3-94cd-fddf71fd83b2%2FHow_to_Create_a_Temporary_Discord_Voice_Channel_System_with_BotGhost.jpeg/size/?exp=1737788443&sig=R0nprySXO_lLumXFvscdT1Lv9bQQzMlTla5dl9UUkX4",
                link: "https://botghost.com/community/how-to-create-a-discord-ticket-bot"
            },
            {
                title: "How to get the new Active Developer Discord Badge without coding",
                image: "https://usenotioncms.com/proxy/block/09dfdd7f-f14b-458c-af08-40ff4720f0d6%2Fa00b9c4b-cec5-4ce9-bd97-ecd9e64b6498%2FIMG_2250-1170x508.png",
                link: "https://botghost.com/community/active-developer-badge"
            },
        ];

        return (
            <div className="bg-[#242830] py-24">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl md:text-5xl font-bold text-white mb-4 leading-tight">
                            Discover <span className="text-[#f45142]">Tutorials</span>, Documentation and Guides
                        </h2>
                        <p className="text-[#9B9D9F] text-xl max-w-3xl mx-auto mt-6">
                            Whether you're new to Discord or have plenty of experience, our team creates tutorials
                            and resources to help you get rolling on your server. Check out our tutorials and get started today!
                        </p>
                    </div>

                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {tutorials.map((tutorial, index) => (
                            <a
                                key={index}
                                href={tutorial.link}
                                target="_blank"
                                className="bg-[#1E2124] rounded-xl overflow-hidden group hover:scale-105 transition-transform duration-300"
                            >
                                <div className="relative aspect-video">
                                    <img
                                        src={tutorial.image}
                                        alt={tutorial.title}
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                                <div className="p-6">
                                    <h3 className="text-xl font-bold text-white group-hover:text-[#f45142] transition-colors duration-200">
                                        {tutorial.title}
                                    </h3>
                                    <p className="text-[#9B9D9F] mt-2 text-sm">
                                        Keep reading
                                    </p>
                                </div>
                            </a>
                        ))}
                    </div>

                    <div className="text-center mt-12">
                        <a
                            href="https://botghost.com/community"
                            target="_blank"
                            className="inline-block text-white font-semibold hover:text-[#f45142] transition-colors duration-200"
                        >
                            Learn more <span className="ml-2">›</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default TutorialsSection; 