import React, { Component } from 'react';

export class HeroFeature extends Component {
    render() {
        const {
            title = <>Build commands with our <span className="text-white">visual builder</span></>,
            description = "Create complex bot commands without any coding. Our drag-and-drop interface makes it easy to build powerful Discord bot features.",
            image = "https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif",
            bulletPoints = [
                "Visual command builder",
                "Real-time preview",
                "No coding required"
            ],
            ctaText = "Try it now",
            ctaLink = "https://dashboard.botghost.com/dashboard"
        } = this.props;

        return (
            <div className="bg-[#f45142] py-32">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-24 items-center">
                        <div>
                            <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
                                {title}
                            </h2>
                            <p className="text-xl font-medium text-white/90 mb-10">
                                {description}
                            </p>
                            <ul className="space-y-4 mb-8">
                                {bulletPoints.map((point, index) => (
                                    <li key={index} className="flex items-center text-white/90">
                                        <svg className="w-6 h-6 mr-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="text-lg font-medium">{point}</span>
                                    </li>
                                ))}
                            </ul>
                            <a
                                href={ctaLink}
                                className="inline-block bg-[#1E2124] text-white px-10 py-4 rounded-full text-lg font-bold hover:bg-white hover:text-[#f45142] transition-colors duration-200"
                            >
                                {ctaText}
                            </a>
                        </div>
                        <div className="relative hidden lg:block">
                            <div className="absolute -inset-8 bg-white/10 rounded-xl transform rotate-3"></div>
                            <div className="relative bg-[#1E2124] rounded-lg overflow-hidden shadow-2xl transform scale-125">
                                <img
                                    src={image}
                                    alt="Feature preview"
                                    className="w-full h-auto transform scale-110"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeroFeature; 