import React, { Component } from 'react';

export class StatsSection extends Component {
    render() {
        const {
            title = <>The most popular <span className="text-[#f45142]">Discord bot maker</span></>,
            description = "Join thousands of users who've created their perfect Discord bot with our easy-to-use bot maker",
            stats = [
                {
                    number: "1.5M+",
                    label: "Users",
                    description: "Active users trust our Discord bot maker"
                },
                {
                    number: "10M+",
                    label: "Commands Created",
                    description: "Custom commands built with our bot maker"
                },
                {
                    number: "100M+",
                    label: "Event Triggers",
                    description: "Automated events handled monthly"
                },
                {
                    number: "1B+",
                    label: "Commands Executed",
                    description: "Total bot commands run by our users"
                },
                {
                    number: "500K+",
                    label: "Servers",
                    description: "Discord servers powered by our bots"
                },
                {
                    number: "2M+",
                    label: "Bots Created",
                    description: "Discord bots made with BotGhost"
                }
            ]
        } = this.props;

        return (
            <div className="bg-[#242830] py-24">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">
                            {title}
                        </h2>
                        <p className="text-[#9B9D9F] text-xl max-w-3xl mx-auto">
                            {description}
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
                        {stats.map((stat, index) => (
                            <div
                                key={index}
                                className="bg-[#1E2124] p-8 rounded-xl text-center transform hover:scale-105 transition-transform duration-200 hover:bg-[#2A2D35]"
                            >
                                <div className="text-4xl md:text-5xl font-bold text-[#f45142] mb-2">
                                    {stat.number}
                                </div>
                                <div className="text-xl font-bold text-white mb-2">
                                    {stat.label}
                                </div>
                                <div className="text-[#9B9D9F]">
                                    {stat.description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default StatsSection; 