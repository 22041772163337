import React, { Suspense, lazy, Component } from 'react';
import { connect } from 'react-redux';
import { Home } from './pages/Home';

import { Route, Switch, Redirect } from 'react-router-dom';


import ReactGA from 'react-ga';
import history from './history';
import NewHome from './pages/NewHome';
import DiscordMusicBot from './pages/ContentGapTempalte';
import NewModule from './pages/NewModule';
import Market from './pages/market/Market';
import MarketSearch from './pages/market/MarketSearch';
import BlogRedirect from './pages/BlogRedirect';
import Privacy from './pages/Privacy';
import ToS from './pages/ToS';
import IdLookup from './pages/tools/IdLookup';
import Tools from './pages/tools/Tools';
import PermissionsCalc from './pages/tools/PermissionsCalc';
import TimeConverter from './pages/tools/TimeConverter';
import NotFound from './pages/404';
import EmbedBuilder from './pages/tools/EmbedBuilder';
import IFTTTtemplate from './pages/IFTTTtemplate';
import VanityChecker from './pages/tools/VanityChecker';
import HexToDecimal from './pages/tools/HexToDecimal';
import Cookies from 'js-cookie';
import AffiliateTOS from './pages/AffiliateTOS';
import Affiliate from './pages/Affiliate';
import CustomBotHosting from './pages/features/CustomBotHosting';
import Footer from './pages/elements/Footer';
import ActiveDevBadge from './pages/features/ActiveDevBadge';
import DiscordBotMaker from './pages/features/DiscordBotMaker';
import DiscordBotCommandBuilder from './pages/features/DiscordBotCommandBuilder';
import DiscordBotEventBuilder from './pages/features/DiscordBotEventBuilder';
import CustomDiscordBot from './pages/features/CustomDiscordBot';


const Bots = React.lazy(() => import("./pages/Bots"));
const MarketBot = React.lazy(() => import("./pages/market/Bot"));
const Modules = React.lazy(() => import("./pages/Modules"));
const Module = React.lazy(() => import("./pages/Module"));
const Leaderboard = React.lazy(() => import("./pages/Leaderboard"));
const Search = React.lazy(() => import("./pages/Search"));
const Blog = React.lazy(() => import("./pages/Blog"));
const BlogBotListPost = React.lazy(() => import("./pages/BlogBotListPost"));
const HowToBlogPost = React.lazy(() => import("./pages/HowToBlogPost"));
const Bot = React.lazy(() => import("./pages/Bot"));
const Tutorials = React.lazy(() => import("./pages/Tutorials"));
const Partners = React.lazy(() => import("./pages/Partners"));
const Security = React.lazy(() => import("./pages/Security"));
const ModuleTemplate = React.lazy(() => import("./pages/ModuleTemplate"));
const InviteChecker = React.lazy(() => import("./pages/tools/InviteChecker"));



ReactGA.initialize("UA-122665050-1", {
    gaOptions: {
        cookieDomain: "auto"
    }
});



// history.listen(location => {
//     console.log("LOCATION CHANGE")
//     ReactGA.set({ page: location.pathname }); // Update the user's current page
//     ReactGA.pageview(location.pathname); // Record a pageview for the given page
//   });

export class App extends Component {

    //     history.listen(location => {
    //     console.log("LOCATION CHANGE")
    //     ReactGA.set({ page: location.pathname }); // Update the user's current page
    //     ReactGA.pageview(location.pathname); // Record a pageview for the given page
    //   });

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props.location.pathname);
    }

    componentDidMount() {
        // Check if landing page is already set
        if (!Cookies.get('landing_page')) {
            console.log(window.location.pathname, 'LANDING PAGE');
            // Set landing page cookie with domain option
            Cookies.set('landing_page', window.location.pathname, {
                expires: 30,
                domain: '.botghost.com' // Note the leading dot to include all subdomains
            });
            console.log(Cookies.get('landing_page'), 'LANDING PAGE COOKIE');

            // Optionally track referrer as well
            if (document.referrer) {
                Cookies.set('referrer', document.referrer, {
                    expires: 30,
                    domain: '.botghost.com'
                });
            }
        }


    }

    renderModulePages = () => {
        return (<Route path="/moderation" component={NewModule}></Route>
        );
    };
    render() {
        return (
            <Suspense fallback={<div></div>}>
                <Switch>
                    {/* <Route exact path="/features" component={NewHome}></Route> */}
                    <Route exact path="/modules/:slug" component={ModuleTemplate}></Route>
                    <Route exact path="/ifttt/:slug" component={IFTTTtemplate}></Route>
                    {/* <Route exact path="/modules/:slug" component={Module}></Route> */}
                    {/* <Route exact path="/bots" component={Bots}></Route>
                <Route exact path="/bot/:id" component={Bot}></Route> */}
                    <Route exact path="/search" component={Search}></Route>
                    <Route exact path="/leaderboard/:id" component={Leaderboard}></Route>
                    <Route exact path="/leaderboard/:id/:bot_id" component={Leaderboard}></Route>
                    <Route exact path="/blog" component={() => { window.location.replace("https://botghost.com/blog/"); }}></Route>
                    {/* <Route exact path="/blog/:tag" component={Tutorial}></Route> */}
                    <Route exact path="/content/:id" component={BlogBotListPost}></Route>
                    <Route exact path="/blog/:id" component={BlogRedirect}></Route>
                    <Route exact path="/security" component={Security}></Route>

                    {/* <Route path="/tutorials/:id" component={HowToBlogPost}></Route> */} */}
                    <Route path="/tutorials" component={() => { window.location.replace("/"); }}></Route>
                    {/* <Route path="/partners" component={Partners}></Route> */}
                    {/* <Route  path="/newhome" component={NewHome}></Route> */}
                    <Route path="/marketplace/bot/:id" component={MarketBot}></Route>
                    <Route path="/marketplace/search" component={MarketSearch}></Route>
                    <Route path="/marketplace" component={Market}></Route>
                    <Route path="/privacy-policy" component={Privacy}></Route>
                    <Route path="/affiliate-tos" component={AffiliateTOS}></Route>
                    <Route path="/affiliate" component={Affiliate}></Route>
                    <Route path="/tos" component={ToS}></Route>
                    {/* {this.renderModulePages()} */}


                    {/* Content Gap Pages */}


                    <Route exact path="/discord-music-bot" component={DiscordMusicBot}></Route>



                    {/* Tools */}
                    <Route exact path="/tools/discord-user-id-lookup" component={IdLookup}></Route>
                    <Route exact path="/tools/discord-permissions-calculator" component={PermissionsCalc}></Route>
                    <Route exact path="/tools/discord-time-converter" component={TimeConverter}></Route>
                    <Route exact path="/tools/discord-embed-builder" component={EmbedBuilder}></Route>
                    <Route exact path="/tools/discord-invite-checker" component={InviteChecker}></Route>
                    <Route exact path="/tools/discord-vanity-checker" component={VanityChecker}></Route>
                    <Route exact path="/tools" component={Tools}></Route>
                    <Route exact path="/tools/hex-to-decimal" component={HexToDecimal}></Route>



                    <Route exact path="/features/discord-bot-hosting" component={
                        () => {
                            window.location.replace("/discord-bot-hosting");
                        }
                    }></Route>
                    {/* Features */}
                    <Route exact path="/discord-bot-hosting" component={CustomBotHosting}></Route>
                    <Route exact path="/active-developer-badge" component={ActiveDevBadge}></Route>
                    <Route exact path="/discord-bot-maker" component={DiscordBotMaker}></Route>
                    <Route exact path="/discord-bot-command-builder" component={DiscordBotCommandBuilder}></Route>
                    <Route exact path="/discord-bot-event-builder" component={DiscordBotEventBuilder}></Route>
                    <Route exact path="/custom-discord-bot" component={CustomDiscordBot}></Route>
                    {/* Content Gap Page (END) */}
                    {/* Redirect to /modules/:slug */}

                    <Route exact path="/reaction-roles" component={() => {
                        window.location.replace("/modules/reaction-roles");
                    }}></Route>

                    <Route exact path="/" component={NewHome}></Route>
                    <Route path="/" component={NotFound}></Route>

                </Switch>
                <Footer />
            </Suspense>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(App);
