import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import NewHomeNav from '../elements/NewHomeNav';
import SeoHeader from '../seo_elements/SeoHeader';
import FeatureSection from '../seo_elements/FeatureSection';
import SecondaryFeatures from '../seo_elements/SecondaryFeatures';
import Testimonial from '../seo_elements/Testimonial';
import CTABanner from '../seo_elements/CTABanner';
import FAQ from '../seo_elements/FAQ';
import TestimonialCollage from '../seo_elements/TestimonialCollage';
import StepByStep from '../seo_elements/StepByStep';
import YouTubeSection from '../seo_elements/YouTubeSection';
import MainNav from '../elements/MainNav';
import ModulesSection from '../seo_elements/ModulesSection';
import RedCTA from '../seo_elements/RedCTA';
import HomeCommandBuilder from '../elements/HomeCommandBuilder';
import TutorialsSection from '../seo_elements/TutorialsSection';
import SEOBuilderDemo from '../seo_elements/SEOBuilderDemo';

export class CustomBotHosting extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        const testimonials = [
            {
                title: "Host your own custom Discord bot",
                subtitle: "Create a custom Discord bot with no coding required",
                quote: "BotGhost makes your dream of owning a custom bot a reality with the easiest and most user-friendly experience!",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ninjapfp.webp",
                name: "NinjaChato",
                role: "Custom bot owner"
            },
            {
                quote: "BotGhost revolutionized our Discord with automation and interactive features, making our server efficient and engaging!",
                title: "Automate your Discord server",
                subtitle: 'Automate your Discord server with our custom built modules',
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/static-1-.png",
                name: "Silas",
                role: "Server Owner"
            },
            {
                quote: "BotGhost lets Rolace Legacy create quick, complex projects with no scripting, using powerful tools for seamless customization",
                title: "Create complex Discord bots",
                subtitle: "Host and create your own complex Discord bots",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/AstorRolace.png",
                name: "Astor Rolace",
                role: "Bot Creator"
            },
            {
                quote: "As a public bot owner BotGhost has given me the ability to create anything my brain can think of!",
                title: "Design your own public Discord bots",
                subtitle: "Become a professional bot designer",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Untitled294_20240719135012.png",
                name: "Oni",
                role: "Bot Designer"
            },
            {
                quote: "Easy to use, reliable, and looks great. I get so many compliments on my bots.",
                title: "Reliable Discord bot hosting",
                subtitle: "Host your own Discord bot with 99.9% uptime",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/Fq2LXwKagAEpknu.jpg",
                name: "clowdy",
                role: "Discord Bot Owner"
            },
            {
                quote: "BotGhost is amazing! Its features and ease of use made me upgrade from monthly to a lifetime subscription without hesitation!",
                title: "Easy to use Discord Bot Maker",
                subtitle: "Create your own Discord bot with ease",
                avatarUrl: "https://botghost-cdn.s3.us-east-2.amazonaws.com/discotools-xyz-icon.png",
                name: "Nathan",
                role: "Server Admin"
            }
            // ... more testimonials
        ];
        const mainFeatures = [
            {
                title: "Hassle free bot hosting",
                description: "Keep your Discord bot running smoothly with our enterprise-grade hosting infrastructure supplied by AWS. Get detailed error logs, automatic restarts, crash management and instant notifications if any issues arise.",
                bulletPoints: [
                    "24/7 uptime monitoring",
                    "Automatic crash recovery"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/HassleFreeHostBG.png"
            },
            {
                title: "Drag and Drop Builder",
                description: "Utilize the first drag and drop Discord bot builder. Create your own commands and events with ease. Absolutely no coding required.",
                bulletPoints: [
                    "No coding required",
                    "Create your own commands and events"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/BuilderBG.gif"
            },
            {
                title: "One-Click Deployment",
                description: "Deploy your Discord bot instantly with our streamlined hosting platform. No complex setup required - just connect your bot and we handle the rest.",
                bulletPoints: [
                    "Automated deployment process",
                    "Instant configuration updates"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/OneClickDeployBG.png"
            },
            {
                title: "Advanced Security",
                description: "Keep your bot and data secure with enterprise-grade security features. Benefit from automated backups, encryption, IP rotation, DDOS protection and more.",
                bulletPoints: [
                    "24/7 DDoS protection",
                    "Automated daily backups"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/SecurityBG.png"
            },
            {
                title: "Scalable Infrastructure",
                description: "Grow your bot without limitations. Our infrastructure scales automatically as your bot's needs increase, ensuring smooth performance at any size.",
                bulletPoints: [
                    "Priority hosting",
                    "Scalable resources"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/ScalableInfastructureBG.png"
            },
            {
                title: "Database Management",
                description: "Manage your bot's data with ease. Our platform includes a built-in database management system that allows you to store and retrieve data for your bot's commands and events.",
                bulletPoints: [
                    "Built-in database management",
                    "Store and retrieve data for your bot's commands and events"
                ],
                image: "https://botghost-cdn.s3.us-east-2.amazonaws.com/DatabaseBG.png"
            }
        ];

        const secondaryFeatures = [
            {
                title: "Drag and Drop Builder",
                description: "Create your own commands and events with ease. Absolutely no coding required.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/drag-and-drop.png"
            },
            {
                title: "24/7 Hosting",
                description: "Keep your Discord bot running smoothly with our enterprise-grade hosting infrastructure supplied by AWS. ",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/247-hosting.png"
            },
            {
                title: "Advanced Dashboard",
                description: "Monitor your bot's performance and manage settings from our intuitive control panel.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/advanced-dashboard.png"
            },
            {
                title: "Custom Built Modules",
                description: "We have a range of custom built modules that you can use to enhance your bot's functionality. These modules are designed to be easy to use and understand, and are built to be compatible with our drag and drop builder.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/custommodules.png"
            },
            {
                title: "BotPanel Integration",
                description: <>Take your Bot to the next level by integrating with our partner service <a className='text-[#f45142]' href="https://botpanel.gg" target="_blank">BotPanel</a>. Create a custom dashboard for your bot and let your users manage their own servers.</>,

                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/botpanelintegration.png"
            },
            {
                title: "Active Developer Badge",
                description: "Get your Discord Active Developer badge by hosting your bot on BotGhost. Simply enable a module and run a command to get your badge.",
                icon: "https://botghost-cdn.s3.us-east-2.amazonaws.com/active_dev.png"
            },
        ];

        const faqQuestions = [
            {
                question: "How do I host my discord bot?",
                answer: "You can host your discord bot by simply copy pasting your bot's token into BotGhost. Once you have done this your bot will automatically be deployed and hosted."
            },
            {
                question: "Do you provide support?",
                answer: "Yes, we provide 24/7 technical support through our Discord server and ticket system. We have a group of volunteer support staff ready in our server."
            },
            {
                question: "How does the pricing work?",
                answer: "We have a free tier available which lets you host your bot for free. We also have a premium tier which includes all features and removes all command and event limits. Pricing is available on a monthly, yearly or lifetime basis."
            },
            {
                question: "Can I get a refund?",
                answer: "We offer a 7 day free trial for all new users. If you are not satisfied with the service within this time period you can request a refund and we will process it immediately."
            },
            {
                question: "What features are included?",
                answer: "All plans include essential features like 24/7 uptime, automatic restarts, performance monitoring, and scaling capabilities. Premium features include priority support, unlimited command and event limits, and more."
            }
            ,
            {
                question: "Can I integrate APIs into my bot?",
                answer: "Absolutely! You can connect external APIs using the command and event builder for advanced functionality."
            },
            {
                question: "Is my data secure?",
                answer: "Yes, we use industry-standard encryption and follow strict privacy policies. Learn more at https://botghost.com/security and https://botghost.com/privacy-policy."
            }
        ];



        return (
            <div>
                <Helmet>
                    <title>Discord Bot Hosting | BotGhost</title>
                    <meta name="description" content="Professional Discord bot hosting with 99.9% uptime. Create and host your custom Discord bot with zero coding required." />
                </Helmet>

                <NewHomeNav version="v2" style={"no-border"} />
                {/* <MainNav /> */}
                <SeoHeader
                    title={
                        <>
                            No Code <span className="text-[#f45142]">Discord Bot Hosting</span></>
                    }
                    description="Create and host your custom Discord bot with 99.9% uptime. No coding required - just click, customize, and launch your bot in minutes. Remove the hassle of managing your bot with no code Discord bot hosting."
                    cta="Start hosting for free"
                    ctaSecondary="View features"
                    ctaSecondaryLink="#features"
                    stats={true}
                />

                <div className="hidden md:block">
                    <SEOBuilderDemo />
                </div>

                <FeatureSection
                    id="features"
                    title={
                        <>
                            Powerful Discord Bot Hosting with <span className="text-[#f45142]">endless features</span>
                            {/* Track <span className="text-[#f45142]">seamless</span> bot hosting
                            <br /> with our platform */}
                        </>
                    }
                    description="Discover our comprehensive suite of Discord bot hosting features designed to give you complete control. Create your own commands and events with our drag and drop builder."

                    features={mainFeatures}
                />

                {/* <HomeCommandBuilder /> */}

                <Testimonial
                    quote="BotGhost provides me with a convenient way to build custom bots for myself and various content creators I work for."
                    author="Discord Server Admin"
                    role="Admin for multiple Discord servers"
                    image="https://botghost-cdn.s3.us-east-2.amazonaws.com/SchnifL_pfp_r2.png"
                />


                <TestimonialCollage
                    testimonials={testimonials}
                />

                <FAQ questions={faqQuestions} />

                <SecondaryFeatures
                    title={
                        <>
                            Everything you need to host a <span className="text-[#f45142]">powerful Discord bot</span>. No coding required.
                        </>
                    }
                    description="Create and host your custom Discord bot with your own custom commands and events."
                    features={secondaryFeatures}
                />
                <ModulesSection />
                <RedCTA
                    title="Ready to power up your Discord server?"
                    subtitle="Join thousands of server owners who trust BotGhost for reliable, feature-rich Discord bot hosting."
                    buttonText="Start hosting for free"
                />
                <TutorialsSection />
                <YouTubeSection
                    title="Watch How It Works"
                    description="Learn how to start hosting your Discord bot on BotGhost"
                />

                <StepByStep
                    title="Simple steps to start hosting your Discord bot on BotGhost"
                    description="Get started with professional Discord bot hosting in minutes"
                />

                <CTABanner
                    title="Start building your Discord bot"
                    highlight="today"
                    subtitle="Get started with professional Discord bot hosting in minutes"
                    ctaText="Start hosting for free"
                    ctaLink="https://dashboard.botghost.com/dashboard"
                />


            </div>
        );
    }
}

export default CustomBotHosting;
